import React, { useEffect } from "react";
import "../Privacy&Policy/Privacyandpolicy.css";
import { Helmet } from "react-helmet-async";

const Privacyandpolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []);

  return (
    <>
      {/* Add meta title and description */}
      <Helmet>
        <title>Privacy and Policy | Skilled Movers</title>
        <meta
          name="description"
          content="Learn how Skilled Movers protects your personal information. Our Privacy Policy outlines data collection, usage, and security practices to ensure your privacy during moving services."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      {/* end of meta title */}
      <div className="banner__image mobile__banner__image">
        <h1 className="privacy_and_policy_text">Privacy and Policy</h1>
      </div>

      <div className="container my-5">
        <div className="row">
          <div style={{ textAlign: "justify" }}>
            <p>
              At Skilled Movers, we value the trust you place in us and are
              committed to protecting your personal information. This Privacy
              Policy outlines how we collect, use, disclose, and safeguard your
              information when you engage with our services, visit our website,
              or communicate with us.
            </p>

            <ol className="information__justify">
              {/* 1 info */}
              <li>
                <b>Information We Collect</b>
              </li>
              <span>We collect the following types of information:</span>
              <ul className="mb-2 privacy__subpoints">
                <li>
                  Personal Information: This may include your name, address,
                  email address, phone number, and other contact details.
                </li>
                <li>
                  Service Information: Details about the moving services you
                  request, such as the origin and destination addresses,
                  inventory of items, and preferred moving dates.
                </li>
                <li>
                  Payment Information: Payment details necessary for processing
                  transactions, such as credit card numbers or bank details,
                  which are stored securely with trusted payment processors.
                </li>
                <li>
                  Website Usage Data: Information gathered automatically through
                  cookies or similar technologies about your visit to our
                  website, such as IP address, browser type, and pages viewed.
                </li>
              </ul>

              {/* 2 info */}

              <li>
                <b>How We Use Your Information</b>
              </li>
              <span>We use your information to:</span>
              <ul className="mb-2 privacy__subpoints">
                <li>Provide and manage moving and packing services.</li>
                <li>Process payments and send you transaction details.</li>
                <li>
                  Communicate with you regarding your service inquiries,
                  schedules, or any updates.
                </li>
                <li>
                  Improve our website and customer experience by analyzing usage
                  trends.
                </li>
                <li>
                  Comply with legal obligations and protect our business
                  interests.
                </li>
              </ul>

              {/* 3 info */}
              <li>
                <b>How We Share Your Information</b>
              </li>
              <span>We may share your information with:</span>
              <ul className="mb-2 privacy__subpoints">
                <li>
                  Third-Party Service Providers: For purposes such as payment
                  processing, IT services, or customer support.
                </li>
                <li>
                  Business Partners: In cases where we collaborate with trusted
                  partners to deliver or enhance our services.
                </li>
                <li>
                  Legal Authorities: If required by law or in response to legal
                  proceedings, government agencies, or for fraud prevention.
                </li>
                <li>
                  We do not sell or rent your personal information to third
                  parties.
                </li>
              </ul>

              {/* 4 info */}
              <li>
                <b>Data Security</b>
              </li>
              <p>
                We implement strict security measures to protect your personal
                data from unauthorized access, disclosure, or alteration.
                However, please note that no transmission over the internet is
                entirely secure, and we cannot guarantee the absolute security
                of your data.
              </p>

              {/* 5 info */}
              <li>
                <b>Cookies and Tracking Technologies</b>
              </li>
              <p>
                Our website uses cookies and similar technologies to enhance
                your browsing experience and gather data about site traffic and
                interactions. You can disable cookies through your browser
                settings, but this may limit your ability to use some features
                of our website.
              </p>

              {/* 6 info */}
              <li>
                <b>Your Rights and Choices</b>
              </li>
              <span>You have the right to:</span>
              <ul className="mb-2 privacy__subpoints">
                <li>Access and correct your personal data.</li>
                <li>
                  Request the deletion of your personal data, subject to legal
                  or contractual limitations.
                </li>
                <li>
                  Opt out of marketing communications at any time by following
                  the unsubscribe instructions in our emails.
                </li>
              </ul>

              {/* 7 info */}
              <li>
                <b>Changes to this Privacy Policy</b>
              </li>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or legal requirements. The updated
                policy will be posted on our website with the revised effective
                date.
              </p>

              {/* 8 info */}
              <li>
                <b>Contact Us</b>
              </li>
              <p>
                If you have any questions about this Privacy Policy or wish to
                exercise your rights, please contact us at:
              </p>
              <div className="contact-details">
                <div className="contact-item">
                  <i
                    className="fas fa-envelope me-2"
                    style={{ color: "#ff5c00" }}
                  ></i>{" "}
                  <a
                    href="mailto:Info@skilledmovers.com.au"
                    className="text-decoration-none conatctmail"
                    style={{ color: "rgb(126, 126, 126)" }}
                  >
                    Info@skilledmovers.com.au
                  </a>
                </div>
                <div className="contact-item">
                  <i
                    className="fas fa-phone me-2"
                    style={{ color: "#ff5c00" }}
                  ></i>{" "}
                   <a
                    href="tel: +03 8907 0486"
                    className="text-decoration-none conatctmail"
                    style={{ color: "rgb(126, 126, 126)" }}
                  >
                    +03 8907 0486 /
                  </a>
                  <a
                    href="tel: +0450 383 690"
                    className="text-decoration-none conatctmail"
                    style={{ color: "rgb(126, 126, 126)" }}
                  >
                   {" "} +0450 383 690
                  </a>
                </div>
              </div>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacyandpolicy;
