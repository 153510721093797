import React, { useEffect, useState, useRef, useCallback } from "react";
import "../Localremovalists/Localremovalists.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import "../Home/Home.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import "../Thankyou/Thankyou.css";

const googleMapsApiKey = "AIzaSyAtUa8t-LE_sdMtpNgvfBSbfE2Sil2cVvw";

const Thankyou = () => {
  const pickupRef = useRef(null);
  const dropoffRef = useRef(null);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
      script.async = true;
      // script.onload = () => {
      //   if (window.google && window.google.maps) {
      //     new window.google.maps.places.Autocomplete(pickupRef.current, {
      //       types: ["geocode"],
      //     });
      //     new window.google.maps.places.Autocomplete(dropoffRef.current, {
      //       types: ["geocode"],
      //     });
      //   }
      // };

      script.onload = () => {
        if (window.google && window.google.maps) {
          const options = {
            types: ["geocode"],
            componentRestrictions: { country: "AU" },
          };

          new window.google.maps.places.Autocomplete(
            pickupRef.current,
            options
          );
          new window.google.maps.places.Autocomplete(
            dropoffRef.current,
            options
          );
        }
      };
      script.onerror = () => {
        console.error("Error loading Google Maps script");
      };
      document.head.appendChild(script);
    };

    loadGoogleMapsScript();
  }, []);

  const handleQuoteRequest = useCallback(async () => {
    const fromLocation = pickupRef.current?.value || "";
    const toLocation = dropoffRef.current?.value || "";

    // Validation check
    if (!fromLocation || !toLocation) {
      setErrorMessage("Please provide the locations");
      return;
    }

    try {
      const response = await fetch(
        "https://booking.skilledmovers.com.au/api/new-enquiry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fromlocation: fromLocation,
            tolocation: toLocation,
          }),
        }
      );

      const data = await response.json();
      console.log("Quote data:", data);

      if (data.success) {
        // Save data to local storage
        localStorage.setItem("enquiry_id", data.enquiry_id);
        localStorage.setItem("from_location", fromLocation);
        localStorage.setItem("to_location", toLocation);

        console.log(
          "Enquiry ID, from location, and to location saved to local storage"
        );
        navigate("/accessproperties");
        window.location.reload();
      } else {
        console.error("Failed to save enquiry:", data.message);
      }
    } catch (error) {
      console.error("Error fetching quote:", error);
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []);

  return (
    <>
      {/* Add meta title and description */}
      <Helmet>
        <title>
          Thank You for Choosing Skilled Movers | Move with Confidence
        </title>
        <meta
          name="description"
          content="Thank you for trusting Skilled Movers. Your move is in good hands. We’ll be in touch soon to ensure a smooth and hassle-free relocation!"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      {/* end of meta title */}

      <div className="banner__image mobile__banner__image">
        <div className="banner skilled__content">
          <div className="text-section mobile__text text-center">
            <h1>Thank You</h1>
          </div>
          {/* <div className="form-section mobile__location w-50">
            <div className="moving-form">
              <h1 class="small-heading fs-4 font-weight-bold">
                Start your better move.
              </h1>

              <div className="form-group-container ">
                <div
                  className="form-group mb-0"
                  style={{ justifyContent: "center" }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                  <input
                    style={{
                      width: "calc(100% - 40px)", 
                      padding: "15px 15px 15px 40px", 
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxSizing: "border-box", 
                      fontSize: "1rem",
                    }}
                    type="text"
                    placeholder="Picking up from"
                    ref={pickupRef}
                  />
                </div>
                <div
                  className="form-group mb-0"
                  style={{ justifyContent: "center" }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                  <input
                    style={{
                      width: "calc(100% - 40px)", 
                      padding: "15px 15px 15px 40px", 
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxSizing: "border-box", 
                      fontSize: "1rem",
                    }}
                    type="text"
                    placeholder="Dropping off at"
                    ref={dropoffRef}
                  />
                </div>
                {errorMessage && (
                  <p
                    style={{
                      color: "red",
                      textAlign: "center",
                      marginTop: "1rem",
                    }}
                  >
                    {errorMessage}
                  </p>
                )}
                <Link
                  to="/accessproperties"
                  className="quote-button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleQuoteRequest();
                  }}
                >
                  Get an Instant Quote
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* ========================================================= */}
      <div className="container my-4">
        <h2 className="text-center mb-3 mb-lg-5 thankyou-heading">
          <FontAwesomeIcon icon={faCheckCircle} className="thankyou-icon" />
          <br />
          Thank you
        </h2>
        <div className="row text-center" style={{ textAlign: "justify" }}>
          <p>Thank you for enquiring about our service. </p>{" "}
          <p>Your message has been sent successfully.</p>{" "}
          <p>
            It has been forwarded to the relevant department and we’ll be in
            touch as soon as possible.
          </p>
        </div>
      </div>
    </>
  );
};

export default Thankyou;
