import React, { useState } from "react";
import "../Aboutus/Aboutus.css";
import "bootstrap/dist/css/bootstrap.min.css";
import man from "../../Assests/medium-shot-man-holding-box.webp";
import mans from "../../Assests/medium-shot-man-holding-boxs.webp";
import { Helmet } from "react-helmet-async";

export default function Aboutus() {
  return (
    <>
      {/* Add meta title and description */}
      <Helmet>
        <title>
          About Us | Skilled Movers - Your Trusted Local Moving Experts
        </title>
        <meta
          name="description"
          content="Discover Skilled Movers, Melbourne’s top moving company. Learn about our commitment to excellence, skilled team, and tailored solutions for a stress-free relocation."
        />
      </Helmet>
      {/* end of meta title */}

      <div className="aboutus__banner__image">
        <h1 className="privacy_and_policy_text">About Us</h1>
      </div>

      <div className="container my-5">
        <div className="row">
          <p style={{ textAlign: "justify" }}>
            At Skilled Movers, we know that moving brings a mix of excitement
            and stress. As trusted removalists in Melbourne, we’re dedicated to
            making your transition seamless. With extensive experience in the
            moving industry, we've earned a reputation for reliability and
            efficiency, whether it’s home relocations, office moves, or
            furniture transportation solutions.
          </p>
        </div>
        <div className="row d-flex justify-content-center align-items-center my-5">
          <div className="col-md-12 col-sm-12 col-lg-6 skilled__movers__images">
            <img src={man} alt="movers" className="img-fluid mover_image" />
          </div>
          <div className="col-md-12 col-sm-12 col-lg-6">
            <h2 className="text-center pt-3 p-lg-0">Skilled Movers</h2>
            <p className="information__justify">
              At Skilled Movers, our professional team in Melbourne takes the
              utmost care with every item, from fragile glassware to bulky
              office furniture, utilizing top-quality packing materials and
              techniques. As a trusted moving company, we ensure your belongings
              arrive at their new destination securely and efficiently.
            </p>
            <p className="information__justify">
              Our removalist service in Melbourne is tailored to eliminate the
              stress of moving. We handle every detail of the logistics, so you
              can focus on settling comfortably into your new space.
            </p>
            <p className="information__justify">
              With a strong dedication to excellence, punctuality, and customer
              satisfaction, we strive to earn your trust with each and every
              move.
            </p>
            <p className="information__justify">
              Choose Skilled Movers for a seamless, dependable, and affordable
              moving experience in Melbourne. Let us help you transition
              smoothly and start your next chapter with ease.
            </p>
          </div>
        </div>
        <div className="row d-flex justify-content-center align-items-center my-5">
          <div className="col-md-12 col-sm-12 col-lg-6">
            <h2 className="text-center pt-3 p-lg-0">
              Discover the Unique Features of Our Removalist Service in
              Melbourne:
            </h2>
            <ul className="information__justify">
              <li>
                From day one, we’ve prioritized transparency, ensuring our
                clients are informed about every aspect of the move.
              </li>
              <li>
                Our services are affordable, designed to prevent unnecessary
                financial strain on our clients.
              </li>
              <li>No hidden fees—what you see is what you get.</li>
              <li>
                We use premium packing materials and techniques, ensuring your
                belongings are fully protected throughout the entire move.
              </li>
              <li>
                Our all-inclusive service handles everything, including
                unpacking and setting up your items at your new location—at no
                extra cost.
              </li>
              <li>
                Our vehicles are meticulously maintained for smooth and seamless
                transport of your assets.
              </li>
              <li>
                Punctuality is a priority. We adhere to schedules, minimizing
                disruptions—whether for office moves or home relocations.
              </li>
            </ul>
          </div>
          <div className="col-md-12 col-sm-12 col-lg-6 skilled__movers__images">
            <img src={mans} alt="movers" className="img-fluid mover_image" />
          </div>
        </div>
      </div>
    </>
  );
}
