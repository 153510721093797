import React, { useEffect, useRef, useState } from 'react';

const GoogleImage = () => {
  const mapRef = useRef(null);
  const fromLocations = localStorage.getItem("from_location");
  const toLocations = localStorage.getItem("to_location");

  const [fromStreetViewImageUrl, setFromStreetViewImageUrl] = useState(null);
  const [toStreetViewImageUrl, setToStreetViewImageUrl] = useState(null);

  useEffect(() => {
    // Initialize the map
    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 15,
    });

    // Function to show the street view image for a given location
    const showStreetViewImage = (locationName, setImageUrl) => {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: locationName }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location;
          map.setCenter(location);

          const streetViewService = new window.google.maps.StreetViewService();
          const STREET_VIEW_MAX_DISTANCE = 50;

          streetViewService.getPanorama({ location, radius: STREET_VIEW_MAX_DISTANCE }, (data, status) => {
            if (status === window.google.maps.StreetViewStatus.OK) {
              const panoramaLocation = data.location.latLng;
              const streetViewImageUrl = `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${panoramaLocation.lat()},${panoramaLocation.lng()}&key=AIzaSyAtUa8t-LE_sdMtpNgvfBSbfE2Sil2cVvw`;
              setImageUrl(streetViewImageUrl);
            } else {
              alert(`No Street View available for ${locationName}.`);
            }
          });
        } else {
          alert('Geocode was not successful for the following reason: ' + status);
        }
      });
    };

    // Show street view images for both locations
    showStreetViewImage(fromLocations, setFromStreetViewImageUrl);
    showStreetViewImage(toLocations, setToStreetViewImageUrl);

  }, [fromLocations, toLocations]); // Run effect whenever fromLocations or toLocations changes

  return (
    <div>
      <div id="map" ref={mapRef} style={{ width: '100%', height: '100%' }}></div>
      <div>
        
        {fromStreetViewImageUrl ? (
          <img src={fromStreetViewImageUrl} alt="Street View From Location" className='img-fluid' />
        ) : (
          <p>Loading street view for from location...</p>
        )}
      </div>
      {/* <div>
        <h3>To Location</h3>
        {toStreetViewImageUrl ? (
          <img src={toStreetViewImageUrl} alt="Street View To Location" />
        ) : (
          <p>Loading street view for to location...</p>
        )}
      </div> */}
    </div>
  );
};

export default GoogleImage;

