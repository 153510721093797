import React, { useState } from "react";
import "../Localremovalists/Localremovalists.css";
import moverman from "../../Assests/man-by-truck-guy-delivery-uniform-man-with-clipboard.webp";
import { Accordion } from "react-bootstrap";
import Whyskilledmovers from "../../Assests/Why-skilled-movers.jpg";

const faqData = [
  {
    question: "Can You Move My Piano?",
    answer:
      "Yes, our furniture removalists specialize in moving pianos. As a professional furniture removal company, our skilled and trained movers handle pianos and other premium items with the utmost care. We recommend informing us about any pianos or heavy items (over 100 kg) when requesting your quote to ensure we provide the best service.",
  },
  {
    question: "Can You Plan a Move within a Week?",
    answer:
      "Yes, we can assist with your move within a week (subject to availability). Our experienced team is equipped to handle urgent moving requests once your booking is confirmed. Reach out to us for fast and efficient removals in Mount Waverley.",
  },
  {
    question: "Why should I choose Skilled as my Movers in Mount Waverley?",
    answer:
      "We recognize that every move is unique, which is why we provide customized solutions to suit your specific needs. With our skilled team, advanced moving vehicles, and high-quality packing materials, we are your top choice for moving services in Mount Waverley.",
  },
  {
    question: "Do You Have an Extra Storage Facility in Mount Waverley?",
    answer:
      "Yes, we can arrange a storage facility for you, subject to availability. To ensure we secure the appropriate size storage, please inform us about your storage needs when you request a quote. For more information, call us at +03 8907 0486 / +0450 383 690 to discuss your storage requirements with our furniture removal team.",
  },
  {
    question:
      "Can your furniture movers handle large items like couches and pianos, as well as delicate items?",
    answer:
      "Yes, our Mount Waverley furniture movers can handle large items like couches and pianos, as well as delicate items. We ensure everything arrives safely and treat all your possessions with care and respect.",
  },
  {
    question: "Will I Be Charged Extra For Moving on Weekends?",
    answer:
      "Weekends can be an ideal time to move, but keep in mind that prices may vary due to higher demand compared to weekdays.",
  },
  {
    question: "Can I Get A Moving Quote For House Relocation in Mount Waverley?",
    answer:
      "Obtaining a quote for your house relocation with Skilled Movers is easy. Simply call us at +03 8907 0486 / +0450 383 690 or complete the form on our website to request your quote.",
  },

  {
    question: "Do You Provide Packing Supplies?",
    answer:
      "Yes, we provide packing supplies. Once you know what types of packing materials you need, simply let us know when requesting a quote. Our moving experts will guide you through the packing material process. We offer cardboard boxes, bubble wrap, plastic sheets, and all other necessary packing materials.",
  },
  {
    question: "Do You Have Adequate Moving Trucks For Offices?",
    answer:
      "Yes, we have a range of well-equipped moving trucks available. As an experienced removalist serving all of Mount Waverley, our teams have successfully handled numerous office relocations over the years. We are fully prepared with the trucks and resources needed for large-scale moving projects.",
  },
  {
    question:
      "As a moving company in Mount Waverley, what additional services do you offer?",
    answer:
      "Skilled Movers offers more than just moving services; we also provide secure storage facilities for your belongings. Our diverse storage options ensure your items are safely stored until you’re ready to bring them into your new space.",
  },
];

const Mountwaverleyinfo = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          {/* Right Column - Text */}
          <div className="col-md-12 d-flex flex-column justify-content-center">
            <h2 className="text-center pt-3 p-lg-0">
              How to save money on removalist services with Skilled Movers
            </h2>
            <p className="information__justify">
              Saving money on removalist services with Skilled Movers can be
              straightforward with a few strategic moves. Start by planning your
              move during off-peak times. Weekends and holidays are busy periods
              for movers, so consider scheduling with Skilled Movers mid-week or
              during the first week of the month for potential savings.
            </p>
            <p className="information__justify">
              Decluttering before packing is another effective approach. Sell or
              donate items you no longer need to lighten your load, which can
              reduce transport costs with Skilled Movers.
            </p>
            <p className="information__justify">
              Request quotes from multiple removalist companies in Mount
              Waverley, including Skilled Movers, to compare prices and
              services. Look for package deals that include packing materials or
              storage options at a discounted rate.
            </p>
            <p className="information__justify">
              Also, handle as much packing yourself as possible. Using your own
              boxes and supplies saves on both labor and material costs when
              working with Skilled Movers.
            </p>
            <p className="information__justify">
              Finally, check for any hidden fees in contracts ahead of time with
              Skilled Movers to avoid unexpected charges that could inflate the
              final bill.
            </p>
          </div>
        </div>
        <div className="row mt-3 mt-lg-5">
          {/* Right Column - Text */}
          <div className="col-md-12 d-flex flex-column justify-content-center">
            <h2 className="text-center">
              Common mistakes to avoid during the moving process
            </h2>
            <p className="information__justify">
              Moving can be a daunting task, and mistakes are easy to make. One
              common slip-up is underestimating the time needed for packing.
              Rushing through this process often leads to disorganization.
            </p>
            <p className="information__justify">
              Another frequent error is neglecting to label boxes clearly.
              Without proper labels, finding essentials in your new home becomes
              a frustrating scavenger hunt.
            </p>
            <p className="information__justify">
              Forgetting about insurance coverage is also critical. Accidents
              happen during transit, and without adequate protection, you risk
              losing valuable items.
            </p>
            <p className="information__justify">
              Many people fail to notify utility companies of their move in
              advance. This oversight may result in inconveniences like no power
              or internet on a moving day.
            </p>
            <p className="information__justify">
              It's essential not to overlook measuring doorways and furniture
              dimensions before the move. Oversized items can become an
              unwelcome surprise when trying to navigate tight spaces.
            </p>
          </div>
        </div>
        <div className="row mt-3 mt-lg-5 d-flex align-items-center">
          {/* Left Column - Image */}
          <div className="col-md-4 d-flex justify-content-center ">
            <img src={Whyskilledmovers} alt="Mover-Man" className="img-fluid" />
          </div>

          {/* Right Column - Text */}
          <div className="col-md-8 d-flex flex-column justify-content-center">
            <h2 className="text-center pt-3 p-lg-0">Why Skilled Movers?</h2>
            <p className="information__justify">
              Planning a move? Skilled Movers sets a new benchmark for care and
              quality in Mount Waverley. We handle everything from packing to
              moving and unpacking your treasured belongings, ensuring a
              stress-free experience with total peace of mind.
            </p>

            <ol>
              <li className="font-weight-bold">Reliable Professionals</li>
              <p className="information__justify">
                Our team consists of experienced, friendly experts who are
                thoroughly screened and background-checked for your safety and
                trust.
              </p>

              <li className="font-weight-bold">Next-Day Availability</li>
              <p className="information__justify">
                Book your service online in just 60 seconds and secure your
                moving date as soon as tomorrow.
              </p>

              <li className="font-weight-bold">
                Comprehensive Insurance & Supplies
              </li>
              <p className="information__justify">
                Our top-tier removalists come fully equipped, and we offer full
                insurance coverage for added peace of mind.
              </p>
            </ol>
          </div>
        </div>
        <div className="row mt-0 mt-lg-5">
          {/* Right Column - Text */}
          <div className="col-md-12 d-flex flex-column justify-content-center">
            <h2 className="text-center">
              Find the best removal company in Mount Waverley for your needs!
            </h2>
            <p className="information__justify">
              Choosing the right removal company in Mount Waverley, such as
              Skilled Movers, can make all the difference in your moving
              experience. With numerous options available, it's crucial to take
              your time and assess what each company offers. Skilled Movers
              stands out with strong customer reviews and a proven track record
              of reliability.
            </p>
            <p className="information__justify">
              Consider the range of services provided by Skilled Movers to
              ensure they meet your specific needs. Whether you require packing
              assistance or transportation for larger items, understanding
              what's included is essential when making your decision.
            </p>
            <p className="information__justify">
              Don’t forget to gather quotes from multiple companies, including
              Skilled Movers, and compare them. This way, you can find
              affordable yet high-quality services tailored just for you.
            </p>
            <p className="information__justify">
              Remember, a stress-free move involves preparation on your end too.
              By following tips for packing and staying organized well ahead of
              time, you'll set yourself up for success. Choosing a skilled
              removalist team like Skilled Movers is an investment in ensuring
              that your move to Mount Waverley is as smooth as possible during
              this significant life transition.
            </p>
          </div>
        </div>
        <div className="container mt-2 mt-lg-5">
          <div className="row">
            {/* Header Text */}
            <div className="col-12 text-center mb-4">
              <h2>Frequently Asked Questions about Removalists in Mount Waverley</h2>
              <p>
                Learn from the moving experts. Can’t find an answer? Call us on
                +03 8907 0486 / +0450 383 690
              </p>
            </div>
            {/* Accordion Columns */}
            <div className="col-md-6">
              <Accordion activeKey={activeKey}>
                {faqData
                  .slice(0, Math.ceil(faqData.length / 2))
                  .map((faq, index) => (
                    <Accordion.Item
                      eventKey={index.toString()}
                      key={index}
                      onClick={() => handleToggle(index.toString())}
                    >
                      <Accordion.Header>{faq.question}</Accordion.Header>
                      <Accordion.Body className="information__justify">
                        {faq.answer}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            </div>
            <div className="col-md-6 ">
              <Accordion activeKey={activeKey}>
                {faqData
                  .slice(Math.ceil(faqData.length / 2))
                  .map((faq, index) => (
                    <Accordion.Item
                      eventKey={(
                        index + Math.ceil(faqData.length / 2)
                      ).toString()}
                      key={index + Math.ceil(faqData.length / 2)}
                      onClick={() =>
                        handleToggle(
                          (index + Math.ceil(faqData.length / 2)).toString()
                        )
                      }
                    >
                      <Accordion.Header>{faq.question}</Accordion.Header>
                      <Accordion.Body className="information__justify">
                        {faq.answer}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mountwaverleyinfo;
