import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const data = [
  {
    pickupLocation: "200 West St, Suite 900, Financial District, NY 10011",
    dropoffLocation: "400 East Ave, Floor 15, Uptown Towers, NY 10012",
    totalCost: 180,
    status: "Paid",
  },
  {
    pickupLocation: "350 Broadway, Apt 16B, Midtown, NY 10013",
    dropoffLocation: "250 Park Ave, Office 5C, Corporate Center, NY 10014",
    totalCost: 220,
    status: "Unpaid",
  },
  {
    pickupLocation: "100 Lincoln Rd, Condo 30, Seaside Residences, NY 10015",
    dropoffLocation: "500 Madison Ave, Suite 400, Skyline Building, NY 10016",
    totalCost: 275,
    status: "Paid",
  },
  {
    pickupLocation: "300 Lexington Ave, Unit 7, The Metropolitan, NY 10017",
    dropoffLocation: "600 5th Ave, Office 1100, Business Plaza, NY 10018",
    totalCost: 320,
    status: "Unpaid",
  },
  {
    pickupLocation: "150 Central Park West, Penthouse 3, Central Residences, NY 10019",
    dropoffLocation: "700 Wall St, Floor 20, Tower One, NY 10020",
    totalCost: 400,
    status: "Paid",
  },
];


const Schedule = () => {
  return (
    <div>
      <Container id="scrollable-complete">
        <Row>
          {data.map((item, index) => (
            <Col key={index} className="mb-4" xs={12}>
              <div className="shadow p-3  bg-white rounded">
                <h5>Booking {index + 1}</h5>
                <div className="d-flex flex-column flex-sm-row justify-content-between mb-2">
                  <div className="font-weight-bold">Pickup Location:</div>
                  <div className="mt-1 mt-sm-0">{item.pickupLocation}</div>
                </div>
                <div className="d-flex flex-column flex-sm-row justify-content-between mb-2">
                  <div className="font-weight-bold">Dropoff Location:</div>
                  <div className="mt-1 mt-sm-0">{item.dropoffLocation}</div>
                </div>
                {/* <div className="d-flex flex-column flex-sm-row justify-content-between mb-2">
                  <div className="font-weight-bold">Total Cost:</div>
                  <div className="mt-1 mt-sm-0">${item.totalCost}</div>
                </div> */}
                <div className="d-flex flex-column flex-sm-row justify-content-between mb-2">
                  <div className="font-weight-bold">Status:</div>
                  <div
                    className={`mt-1 mt-sm-0 ${
                      item.status === "Paid" ? "status-paid" : "status-unpaid"
                    }`}
                  >
                    {item.status}
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Schedule;
