import React, { useState } from 'react';
import axios from 'axios';

const QuoteDetails = ({ enquiryId }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [detailedData, setDetailedData] = useState(null);

  const toggleDetails = async () => {
    if (!detailsVisible) {
      try {
        const response = await axios.get(`https://booking.skilledmovers.com.au/api/sheduled-view-detailed-estimate-booking/59`);
        if (response.data.success) {
          setDetailedData(response.data);
        }
      } catch (error) {
        console.error("Error fetching detailed data:", error);
      }
    }
    setDetailsVisible(!detailsVisible);
  };

  return (
    <div className="quote-details">
      {/* Add your existing quote details rendering code here */}
      
      <button onClick={toggleDetails} className="btn btn-primary">
        {detailsVisible ? 'Hide Details' : 'Show Details'}
      </button>

      {detailsVisible && detailedData && (
        <div className="row mt-3">
          <div className="col-12">
            <h4>Detailed Estimate Booking Information</h4>
            <p><strong>Customer Name:</strong> {detailedData.enquirydetails.customer_name}</p>
            <p><strong>Customer Email:</strong> {detailedData.enquirydetails.customer_email}</p>
            <p><strong>Customer Phone:</strong> {detailedData.enquirydetails.customer_phone}</p>
            <p><strong>From Location:</strong> {detailedData.enquirydetails.fromlocation}</p>
            <p><strong>To Location:</strong> {detailedData.enquirydetails.tolocation}</p>
            <p><strong>Total Distance:</strong> {detailedData.enquirydetails.total_distance} km</p>
            <p><strong>Created At:</strong> {detailedData.enquirydetails.created_at}</p>
            
            <h5>Room Details</h5>
            {detailedData.enquirys[2].map((item, index) => (
              <div key={index}>
                <p><strong>Room Name:</strong> {item.roomName}</p>
                <p><strong>Custom Item Name:</strong> {item.customItemName}</p>
                <p><strong>Item Name:</strong> {item.itemName}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuoteDetails;
