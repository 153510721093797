import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import "../Faqs/Faqs.css";
import faqs from "../../Assests/faqs.png";
import "bootstrap/dist/css/bootstrap.min.css";

const faqData = [
  {
    question: "What steps should I take for a simultaneous settlement?",
    answer:
      "Notify our team if you have a simultaneous settlement so we can prioritize your move. We'll assign a dedicated truck and crew to handle only your move for the day.",
  },
  {
    question: "What steps should I follow to book a lift?",
    answer:
      "We handle many moves dependent on lift bookings and can adjust loading or unloading times accordingly. Typically, the building manager will provide a 2-hour window for your move.",
  },
  {
    question:
      "What should I do if I have a lot to move but large trucks can’t access my property?",
    answer:
      "Limited access, like narrow driveways or overhanging trees, can be challenging. Discuss options with our team, including using multiple smaller trucks if needed.",
  },
  {
    question: "How can I ensure my belongings will fit on the truck?",
    answer:
      "The Skilled website provides a quick size estimator or inventory tool to help you determine your move size accurately. ",
  },
];

export default function Faqs() {
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <img src={faqs} alt="faq" className="faq-image" />
          <h1 className="faq-heading text-center font-weight-bold mb-0">
            Frequently Asked Questions
          </h1>
        </div>
        <div className="pt-3 col-md-12">
          <h2>Before you book</h2>
          <Accordion activeKey={activeKey}>
            {faqData.map((faq, index) => (
              <Accordion.Item
                eventKey={index.toString()}
                key={index}
                onClick={() => handleToggle(index.toString())}
              >
                <Accordion.Header>{faq.question}</Accordion.Header>
                <Accordion.Body>{faq.answer}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
}
