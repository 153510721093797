import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const Emailinventory = () => {
  const [data, setData] = useState(null);
  const [enquirys, setEnquirys] = useState(null);
  const [roomsData, setRoomsData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const { enquiryId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
       // const quoteId = localStorage.getItem("enquiry_id");
        if (enquiryId) {
          const response = await axios.get(
            `https://booking.skilledmovers.com.au/api/detailed-quote/${enquiryId}`
          );
          setData(response.data.enquirydetails);
          setEnquirys(response.data.enquirys);

          // Process enquirys data
          const processedRoomsData = Object.keys(response.data.enquirys).map(
            (roomId) => {
              const roomItems = response.data.enquirys[roomId];
              return {
                roomName: roomItems[0]?.roomName || "Unknown Room",
                items: roomItems.reduce((acc, item) => {
                  const customItem = acc.find(
                    (ci) => ci.customItemName === item.customItemName
                  );
                  if (customItem) {
                    customItem.items.push({
                      itemId: item.itemId,
                      itemName: item.itemName,
                      quantity: item.quantity,
                    });
                  } else {
                    acc.push({
                      customItemName:
                        item.customItemName || "Unknown Custom Item",
                      items: [
                        {
                          itemId: item.itemId,
                          itemName: item.itemName,
                          quantity: item.quantity,
                        },
                      ],
                    });
                  }
                  return acc;
                }, []),
              };
            }
          );

          setRoomsData(processedRoomsData);
        } else {
          setError("No quote ID found in local storage");
        }
      } catch (error) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [enquiryId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <h2 style={{ color: "#ff5c00" }} className="fs-5">
          Inventory List
        </h2>
        <div>
          <h2 style={{ color: "#ff5c00" }} className="fs-5">
            Quantity
          </h2>
        </div>
      </div>
      <hr />
      {roomsData.map((room) => (
        <div key={room.roomName}>
          <h3 style={{ color: "#ff5c00" }} className="fs-5">
            {room.roomName}
          </h3>
          {room.items.map((customItem) => (
            <div key={customItem.customItemName}>
              <h4 className="fs-5">{customItem.customItemName}</h4>
              <ul className="">
                {customItem.items.map((item) => (
                  <li
                    key={item.itemId}
                    className="d-flex justify-content-between fs-6"
                  >
                    {item.itemName}
                    <div style={{ color: "#292767" }} className="fs-6">
                      {item.quantity}
                    </div>
                  </li>
                ))}
              </ul>
              <hr />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Emailinventory;
