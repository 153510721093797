import React, { useState } from "react";
import "../Photos/Photo.css";
import uplode from "../../Assests/upload.png";

const Photo = () => {
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <>
      <h3 className="text-center fs-4">
        Feel free to upload photos, videos, or documents below.
      </h3>
      <div className="container upload-container">
        <div className="row">
          <div className="col-md-6">
            <div className="upload-section">
              <h2 className="upload-title">Upload photos</h2>
              <p className="upload-subtitle">
                for the property we're picking up from
              </p>
              <div className="photos-count">Photos (0)</div>

              <div className="notes-section">
                <label className="notes-label">Notes (optional)</label>
                <textarea
                  className="notes-input"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter a description..."
                ></textarea>
                <div className="save-notes-container">
                  <button className="save-notes-button">Save notes</button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 pt-3 pt-md-0">
            <div className="upload-section">
              <div className="upload-box">
                <input
                  type="file"
                  onChange={handleFileChange}
                  id="file-upload"
                  hidden
                />
                <label htmlFor="file-upload" className="upload-label">
                  <div className="upload-icon">
                    <img
                      src={uplode}
                      style={{ width: "100%", height: "100%" }}
                      alt="upload icon"
                    />
                  </div>
                  <p className="upload-text" style={{ color: "#ff5c00" }}>
                    Click to upload
                  </p>
                  <p>or drag and drop</p>
                  <p>photos or video files</p>
                </label>
              </div>

              <div className="upload-help">
                Trouble uploading photos?
                <a
                  href="mailto:Info@skilledmovers.com.au"
                  style={{ color: "#ff5c00" }}
                >
                  Email them to Info@skilledmovers.com.au
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Photo;
