import React, { useState } from 'react';
import axios from 'axios';

const QuoteDetails = ({ enquiryId }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [detailedData, setDetailedData] = useState(null);

  const toggleDetails = async () => {
    if (!detailsVisible) {
      try {
        const response = await axios.get(`https://booking.skilledmovers.com.au/api/sheduled-view-quick-estimate-booking/${enquiryId}`);
        if (response.data.success) {
          setDetailedData(response.data.enquiry);
        } else {
          console.error("Failed to fetch detailed data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching detailed data:", error);
      }
    }
    setDetailsVisible(!detailsVisible);
  };

  return (
    <div className="quote-details">
      {/* Add your existing quote details rendering code here */}
      
      <button onClick={toggleDetails} className="btn quote-button">
        {detailsVisible ? 'Hide Details' : 'Show Details'}
      </button>

      {detailsVisible && detailedData && (
        <div className="row mt-3">
          <div className="col-12">
            <h4>Detailed Estimate Booking Information</h4>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
              
                <tr>
                  <td><strong>Movers Count</strong></td>
                  <td>{detailedData.movers_count}</td>
                </tr>
                <tr>
                  <td><strong>Booking Fee</strong></td>
                  <td>{detailedData.bookingfee}</td>
                </tr>
                <tr>
                  <td><strong>How Many Bedrooms</strong></td>
                  <td>{detailedData.how_many_bedroom}</td>
                </tr>
                <tr>
                  <td><strong>Living Areas with Couches</strong></td>
                  <td>{detailedData.living_areas_with_couches}</td>
                </tr>
                <tr>
                  <td><strong>Volume of Furniture</strong></td>
                  <td>{detailedData.volume_of_furniture}</td>
                </tr>
                <tr>
                  <td><strong>Move From Outside Your Home</strong></td>
                  <td>{detailedData.move_from_outside_your_home}</td>
                </tr>
                <tr>
                  <td><strong>Number of Pot Plants</strong></td>
                  <td>{detailedData.number_of_pot_plants}</td>
                </tr>
                <tr>
                  <td><strong>Cubic Size</strong></td>
                  <td>{detailedData.cubic_size}</td>
                </tr>
                <tr>
                  <td><strong>Vehicle Count</strong></td>
                  <td>{detailedData.vehicle_count}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuoteDetails;
