import { useEffect, useState } from "react";
import React from "react";
import "../ContactUs/Contactus.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

export default function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !mobile || !message) {
      toast.error("Please fill out all the fields.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const mobileRegex = /^[0-9]{10}$/;

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!mobileRegex.test(mobile)) {
      toast.error("Please enter a valid number.");
      return;
    }

    const payload = {
      name,
      email,
      mobile_number: mobile,
      message,
    };

    try {
      await axios.post(
        "https://booking.skilledmovers.com.au/api/post-contact-us",
        payload
      );
      // Show success toast
      toast.success("Form submitted successfully!");

      // Clear form fields
      setName("");
      setEmail("");
      setMobile("");
      setMessage("");
      navigate("/thank-you");
    } catch (error) {
      // Show error toast
      toast.error("Error submitting form. Please try again.");
      console.error("Error submitting contact form:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Add meta title and description */}
      <Helmet>
        <title>
          Contact Us | Skilled Movers - Get in Touch for Your Moving Needs{" "}
        </title>
        <meta
          name="description"
          content="Need to move? Contact Skilled Movers in Melbourne for expert 6service. Fill out our form or call for a free estimate and a seamless relocation experience."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      {/* end of meta title */}

      <section className="contact-container">
        <div className="container my-5">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-lg-6  contact-info-container">
              <h1 className="">Give us a call to chat!</h1>
              <p className="text-justify" style={{ textAlign: "justify" }}>
                We move thousands of people annually. Have questions? Call us
                anytime — our friendly team is available 7 days a week to assist
                you.{" "}
              </p>
              <div className="contact-info-item">
                <div className="contact-icon">
                  <i className="fas fa-phone"></i>
                </div>
                <div>
                  <p>Call us on</p>
                  <a href="tel: +03 8907 0486">+03 8907 0486</a> /{" "}
                  <a href="tel: +0450 383 690">+0450 383 690</a>
                </div>
              </div>
              <div className="contact-info-item">
                <div className="contact-icon">
                  <i className="fas fa-envelope"></i>
                </div>
                <div>
                  <p>Email us at</p>
                  <a href="mailto:Info@skilledmovers.com.au">
                    Info@skilledmovers.com.au
                  </a>
                </div>
              </div>
              {/* <div className="contact-info-item">
                <div className="contact-icon">
                  <i
                    className="fas fa-map-marker-alt"
                    style={{ color: "#40e550" }}
                  ></i>
                </div>
                <div>
                  <p>Mail us at</p>
                  <p>Pune.webwideit.solutions.com</p>
                </div>
              </div> */}
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6 ">
              <div className="shadow p-3 bg-white rounded">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="mobile" className="form-label">
                      Mobile Number
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="mobile"
                      placeholder="Enter your mobile number"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      maxLength={10}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                      Message
                    </label>
                    <textarea
                      className="form-control"
                      id="message"
                      rows="4"
                      placeholder="Enter your message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <button type="submit" className="btn quote-button">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
