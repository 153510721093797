import React from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Testimonial/Testimonial.css";
import profileimg from "../../Assests/Testimonial1.webp";

export default function Testimonials() {
  const testimonials = [
    {
      name: "TonyAus",
      text: "Mohit and ankush arrived on time and took great care with our things snd the drop of of great putting things where we requested. Great job snd thank you. Peter and Tony",
      image: profileimg,
    },
    {
      name: "Kritika Basu",
      text: "Very professional from start to  finish. Was easy to deal with Gary and sort out the move. My move was difficult due to stairs but they made it happen smoothly. Also,  the guys at work were amazing. Very polite and efficient. Five star service. Highly recommend.",
      image: profileimg,
    },
    {
      name: "Joel League",
      text: "Fantastic removalists who work fast, efficient and carefully. They helped me move my home and office and I couldn’t be happier. The care and attention provided was second to none, would absolutely book again.",
      image: profileimg,
    },
    {
      name: "Padmanabh Amonkar",
      text: "Jassi and Raj has given the best service for my house movement furniture.. very humble and friendly staff.. took care of all my belongings.. appreciate it.. will definitely recommend.",
      image: profileimg,
    },
  ];

  return (
    <div className="testimonial-section text-white">
      <Container>
        <Row className="mb-4 p-3 p-md-0">
          <Col
            md={6}
            className="d-flex flex-column justify-content-center text-center text-md-start"
          >
            <h3 className="Testminioal_heading">
              <span className="highlight">What</span> Our <br /> Happy{" "}
              <span className="highlight">Client</span> Says <span className="highlight">About Us!</span>
            </h3>
            <p className="fs-5 text-justify" style={{ textAlign: "justify" }}>
              Trusted movers for a smooth, stress-free relocation. Whether it's
              a local or long-distance move, we ensure your belongings are
              handled with care and efficiency every step of the way!{" "}
            </p>
          </Col>
          <Col md={6}>
            <div className="carousel-container">
              <Carousel controls={false} indicators={true}>
                {testimonials.map((testimonial, index) => (
                  <Carousel.Item key={index}>
                    <div className="testimonial-card text-dark p-4 rounded position-relative">
                      <i className="fas fa-quote-left quote-icon-card"></i>
                      <Row className="align-items-center justify-content-center">
                        {/* <Col xs={12} sm={4} className="text-center mb-3 mb-sm-0">
                          <img
                            className="d-block w-100 mx-auto"
                            src={testimonial.image}
                            alt={testimonial.name}
                          />
                        </Col> */}
                        <Col xs={12} sm={8}>
                          <blockquote className="blockquote mb-4">
                            <p
                              className="mb-0"
                              style={{ textAlign: "justify" }}
                            >
                              {testimonial.text}
                            </p>
                          </blockquote>
                          <footer className="blockquote-footer">
                            <strong>{testimonial.name}</strong>{" "}
                            <cite title="Source Title">
                              {testimonial.company}
                            </cite>
                          </footer>
                        </Col>
                      </Row>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
