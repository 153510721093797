import React from "react";
import movers from "../../Assests/movers.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Become_Mover/BecomeMover.css";

export default function BecomeMover() {
  return (
    <div className="container my-5">
      <div className="row mb-4">
        <div className="col-md-12 text-center">
          <h1 className="font-weight-bold">Let's work together</h1>
          <p>
            Are you an owner-driver or small fleet operator seeking steady work
            or a way to fill spare capacity?
          </p>
        </div>
      </div>
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-md-12 col-sm-12 col-lg-6">
          <img src={movers} alt="movers" className="img-fluid mover_image" />
        </div>
        <div className="col-md-12 col-sm-12 col-lg-6">
          <h2 className="pt-2 pt-md-0">
            We're reaching out to connect with you.
          </h2>
          <p className="text-justify">
            We're reaching out to owner-drivers and removalist subcontractors in
            Sydney and Melbourne. We consistently secure more work than our
            current network can handle and are inviting new partners to join us.
          </p>
          <h3>What We're Looking For</h3>
          <ul>
            <li>Honest and hard working.</li>
            <li>Well presented.</li>
            <li>Solutions orientated and able to work independently.</li>
            <li>
              Clean and well maintained vehicles. You can also rent and use
              unbranded trucks.
            </li>
            <li>A good knowledge and understanding of your city’s roads.</li>
            <li>
              A minimum of 1 year experience working in the removals industry.
            </li>
          </ul>
          <h6>
            <a href="#from" className="text-decoration-none">
              Does this sound like you? Tell us about yourself
            </a>
          </h6>
        </div>
      </div>
      <div className="row mb-4 pt-0 pt-md-5">
        <div className="col-md-12">
          <h2 className="text-center">Why Work with Skilled Movers?</h2>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4">
          <div className="d-flex align-items-start">
            <i className="fa fa-check-circle mr-3 mover_check"></i>
            <div>
              <h4 className="font-weight-bold">Flexible Work</h4>
              <p>
                Work up to 7 days a week or just enough to complement your
                existing schedule.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-start">
            <i className="fa fa-check-circle mr-3 mover_check"></i>
            <div>
              <h4 className="font-weight-bold">Low Hassle</h4>
              <p>
                Our user-friendly app simplifies work management, making it easy
                to navigate and handle tasks.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-start">
            <i className="fa fa-check-circle mr-3 mover_check"></i>
            <div>
              <h4 className="font-weight-bold">Great Rates</h4>
              <p>Compensation is at or above market rates.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4">
          <div className="d-flex align-items-start">
            <i className="fa fa-check-circle mr-3 mover_check"></i>
            <div>
              <h4 className="font-weight-bold">High Income</h4>
              <p>
                Earn $3000-$5000 per week with a small truck, $4000-$6000 with a
                large truck, and $6000+ for interstate work.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-start">
            <i className="fa fa-check-circle mr-3 mover_check"></i>
            <div>
              <h4 className="font-weight-bold">Quick Payment</h4>
              <p>We pay every week.</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-start">
            <i className="fa fa-check-circle mr-3 mover_check"></i>
            <div>
              <h4 className="font-weight-bold">
                No More Chasing Work or Paperwork
              </h4>
              <p>
                We manage marketing, sales, operations, finances, and customer
                support. You just focus on the job.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-4">
          <div className="d-flex align-items-start">
            <i className="fa fa-check-circle mr-3 mover_check"></i>
            <div>
              <h4 className="font-weight-bold">You're 100% Supported</h4>
              <p>
                Encounter issues? Our operations center will handle them
                directly with the customer. Join a supportive and friendly team
                instead of working alone.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-start">
            <i className="fa fa-check-circle mr-3 mover_check"></i>
            <div>
              <h4 className="font-weight-bold">Help to Grow</h4>
              <p>
                Ready to expand? Many of our operators have grown their fleets
                from 1 to 5 trucks, thanks to a steady stream of well-paid,
                manageable work.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <div
          className="row mb-4  become_details d-flex align-items-center justify-content-center"
          id="from"
        >
          <div className="col-md-12">
            <h2 className="text-center">Tell us about yourself</h2>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-3">
              <label htmlFor="firstName">
                <span className="text-danger">*</span>First name
              </label>
              <input
                type="text"
                id="firstName"
                className="form-control"
                placeholder="John"
                required
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label htmlFor="lastName">
                <span className="text-danger">*</span>Last name
              </label>
              <input
                type="text"
                id="lastName"
                className="form-control"
                placeholder="Doe"
                required
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label htmlFor="mobile">
                <span className="text-danger">*</span>Mobile
              </label>
              <input
                type="text"
                id="mobile"
                className="form-control"
                placeholder="+61XXXXXXXXXX"
                required
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label htmlFor="email">
                <span className="text-danger">*</span>Email
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                placeholder="email@gmail.com"
                required
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label htmlFor="businessName">
                <span className="text-danger">*</span>Business name
              </label>
              <input
                type="text"
                id="businessName"
                className="form-control"
                placeholder="Acme Moving Company"
                required
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label htmlFor="abn">
                <span className="text-danger">*</span>ABN
              </label>
              <input
                type="number"
                id="abn"
                className="form-control"
                placeholder="12345678901"
                required
              />
            </div>
          </div>
          <div className="col-md-12">
            <h5 className="text-center m-4">
              Business & Availability Information
            </h5>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-3">
              <label htmlFor="operatorStyle">
                <span className="text-danger">*</span>Operator style
              </label>
              <select id="operatorStyle" className="form-control" required>
                <option>Please select one</option>
                <option>Owner driver</option>
                <option>Small fleet operator</option>
              </select>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label htmlFor="yearsExperience">
                <span className="text-danger">*</span>Years of experience
              </label>
              <input
                type="text"
                id="yearsExperience"
                className="form-control"
                placeholder="Whole numbers only"
                required
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label htmlFor="location">
                <span className="text-danger">*</span>Where are your truck(s)
                located?
              </label>
              <input
                type="text"
                id="location"
                className="form-control"
                placeholder="E.g. suburb"
                required
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label htmlFor="services">
                <span className="text-danger">*</span>What type of services do
                you offer (please select):
              </label>
              <div className="form-check" required>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value
                  id="localMoves"
                />
                <label className="form-check-label" htmlFor="localMoves">
                  Local moves
                </label>
              </div>
              <div className="form-check" required>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value
                  id="countryMoves"
                />
                <label className="form-check-label" htmlFor="countryMoves">
                  Country moves (e.g. return same day)
                </label>
              </div>
              <div className="form-check" required>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value
                  id="interstateMoves"
                />
                <label className="form-check-label" htmlFor="interstateMoves">
                  Interstate moves
                </label>
              </div>
              <div className="form-check" required>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value
                  id="packing"
                />
                <label className="form-check-label" htmlFor="packing">
                  Packing
                </label>
              </div>
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="additionalComments">Additional Comments</label>
              <textarea
                id="additionalComments"
                className="form-control"
                rows="4"
                placeholder="Enter any additional information here"
              ></textarea>
            </div>
            <div className="col-12 text-center">
              <button type="submit" className="btn become_submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
