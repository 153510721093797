import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Inventory/Inventory.css";
import { useParams } from "react-router-dom";

function Emailhandleinventory() {
  const { enquiryId } = useParams();
  const [data, setData] = useState({
    enquirydetails: null,
    handelItems: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    //const quoteId = localStorage.getItem("enquiry_id");

    if (enquiryId) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await axios.get(
            `https://booking.skilledmovers.com.au/api/handel-estimate-quote/${enquiryId}`
          );

          // Set both enquirydetails and handelItems in a single state update
          setData({
            enquirydetails: response.data.enquirydetails,
            handelItems: response.data.handelItems,
          });
        } catch (error) {
          setError("Error fetching data");
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    } else {
      setError("No quote ID found in local storage");
      setLoading(false);
    }
  }, [enquiryId]); // Empty dependency array to run only once

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="inventory-container">
      <div className="d-flex justify-content-between">
        <h2 style={{ color: "#ff5c00" }} className="fs-5">
          Inventory List
        </h2>
        <div>
          <h2 style={{ color: "#ff5c00" }} className="fs-5">
            Quantity
          </h2>
        </div>
        <div>
          <h2 style={{ color: "#ff5c00" }} className="fs-5">
            Volume
          </h2>
        </div>
      </div>
      <hr />
      <div className="inventory-details">
        {data.handelItems.length > 0 && (
          <div className="">
            <ul className="p-0" >
              {data.handelItems.map((item) => (
                <li key={item.id} className="d-flex align-items-center justify-content-between fs-6 ">
                  <p>
                    <strong></strong> {item.item_name}
                  </p>
                  <p>
                    <strong></strong> {item.quantity}
                  </p>
                  <p>
                    <strong></strong> {item.volume}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Emailhandleinventory;
