import React, { useState, useEffect } from "react";
import "../Myquote/Myquote.css";
import axios from "axios";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";

import {
  faL,
  faMapMarkerAlt,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import truck from "../../Assests/truck.png";
import team from "../../Assests/team.png";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { Card, Row, Col } from "react-bootstrap";
import delivery from "../../Assests/delivery.png";
import dropoff from "../../Assests/dropoff.png";
import contact from "../../Assests/contact-mail.png";
import time from "../../Assests/time.png";
import myquotetime from "../../Assests/quote.png";
import bill from "../../Assests/bill.png";
import Photo from "../Photos/Photo";
import Inventory from "../Inventory/Inventory";
import hammer from "../../Assests/hammer.png";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePaymentForm from "../StripePayment/Stripepayment";
import GoogleMapComponent from "../GoogleMapComponent ";
import GoogleImage from "./GoogleImage";
import GoogleToImage from "./GoogleToImage";

const stripePromise = loadStripe("your-publishable-key-here");

const dateData = [
  { day: "Monday", date: "29 Jul", price: "$169.00/hr", additional: "" },
  { day: "Tuesday", date: "30 Jul", price: "$169.00/hr", additional: "" },
  { day: "Wednesday", date: "31 Jul", price: "$169.00/hr", additional: "" },
  { day: "Thursday", date: "1 Aug", price: "$169.00/hr", additional: "" },
  { day: "Friday", date: "2 Aug", price: "$179.00/hr", additional: "$35 more" },
  {
    day: "Saturday",
    date: "3 Aug",
    price: "$179.00/hr",
    additional: "$35 more",
  },
  { day: "Sunday", date: "4 Aug", price: "$179.00/hr", additional: "$35 more" },
  { day: "Monday", date: "5 Aug", price: "$180.00/hr", additional: "$35 more" },
];

function InventoryMyquote() {
  const [activeTab, setActiveTab] = useState("summary");
  const [showPickUpModal, setShowPickUpModal] = useState(false);
  const [showDropOffModal, setShowDropOffModal] = useState(false);
  const [showaddcontact, setAddcontact] = useState(false);
  const [activeSection, setActiveSection] = useState(false);
  const [movingsection, setMovingsection] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedOption, setSelectedOption] = useState("default");
  const [showcheaper, setShowcheaper] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showreverse, setShowreverse] = useState(false);
  const [showAssembly, setShowAssembly] = useState(false);
  const [showAssemblyForm, setShowAssemblyForm] = useState(false);
  const [breakdownquote, setBreakdownquote] = useState(false);
  const [grandTotalLow, setGrandTotalLow] = useState("");
  const [grandTotalMedium, setGrandTotalMedium] = useState("");
  const [grandTotalHigh, setGrandTotalHigh] = useState("");
  const [bookingFee, setBookingFee] = useState("");
  const [totalDurationCostBackToBase, setTotalDurationCostBackToBase] =
    useState("");
  const [unLoadingLowTime, setUnLoadingLowTime] = useState("");
  const [unLoadingMediumTime, setUnLoadingMediumTime] = useState("");
  const [loadingMediumTime, setLoadingMediumTime] = useState("");
  const [totalDuration, setTotalDuration] = useState("");

  const [unLoadingHighTime, setUnLoadingHighTime] = useState("");
  const [fromLocation, setFromLocation] = useState("");
  const [toLocation, setToLocation] = useState("");

  const [showLocalModal, setShowLocalModal] = useState(false);
  const [localItems, setLocalItems] = useState([]);
  const [assemblyOptiontypes, setAssemblyOptiontypes] = useState();
  const [grandTotalLoadingLowTime, setGrandTotalLoadingLowTime] = useState("");
  const [grandTotalLoadingMediumTime, setGrandTotalLoadingMediumTime] =
    useState("");
  const [grandTotalLoadingHighTime, setGrandTotalLoadingHighTime] =
    useState("");
  const [grandTotalUnloadingLowTime, setGrandTotalUnloadingLowTime] =
    useState("");
  const [grandTotalUnloadingMediumTime, setGrandTotalUnloadingMediumTime] =
    useState("");
  const [grandTotalUnloadingHighTime, setGrandTotalUnloadingHighTime] =
    useState("");

  const [yesPleaseSelected, setYesPleaseSelected] = useState(true);

  const handleYesNoChange = (e) => {
    setYesPleaseSelected(e.target.value === "yes");
  };

  const bothItems = [
    { item_id: 1, name: "Bed", approx: "10 - 15 minutes", count: 0 },
    { item_id: 2, name: "Desk", approx: "5 - 10 minutes", count: 0 },
    {
      item_id: 3,
      name: "Dining Table (Large only)",
      approx: "5 - 10 minutes",
      count: 0,
    },
    { item_id: 4, name: "Baby Cot", approx: "5 - 10 minutes", count: 0 },
    { item_id: 5, name: "Bunk Bed", approx: "15 - 25 minutes", count: 0 },
    { item_id: 6, name: "Trampoline", approx: "20 - 35 minutes", count: 0 },
  ];

  const disassemblyItems = [
    { item_id: 1, name: "Bed", approx: "5 minutes", count: 0 },
    { item_id: 2, name: "Desk", approx: "5 minutes", count: 0 },
    {
      iitem_idd: 3,
      name: "Dining Table (Large only)",
      approx: "5 minutes",
      count: 0,
    },
    { item_id: 4, name: "Baby Cot", approx: "5 minutes", count: 0 },
    { item_id: 5, name: "Bunk Bed", approx: "5 - 10 minutes", count: 0 },
    { item_id: 6, name: "Trampoline", approx: "10 - 15 minutes", count: 0 },
  ];

  const reassemblyItems = [
    { item_id: 1, name: "Bed", approx: "5 - 10 minutes", count: 0 },
    { item_id: 2, name: "Desk", approx: "5 minutes", count: 0 },
    {
      item_id: 3,
      name: "Dining Table (Large only)",
      approx: "5 minutes",
      count: 0,
    },
    { item_id: 4, name: "Baby Cot", approx: "5 minutes", count: 0 },
    { item_id: 5, name: "Bunk Bed", approx: "10 - 15 minutes", count: 0 },
    { item_id: 6, name: "Trampoline", approx: "15 - 20 minutes", count: 0 },
  ];

  const handleAssemblyOptionChange = (e) => {
    const selectedOption = e.target.value;
    setAssemblyOption(selectedOption);

    let itemsToSet;
    switch (selectedOption) {
      case "disassembly":
        itemsToSet = disassemblyItems.map((item) => ({ ...item, count: 0 }));
        break;
      case "both":
        itemsToSet = bothItems.map((item) => ({ ...item, count: 0 }));
        break;
      case "reassembly":
        itemsToSet = reassemblyItems.map((item) => ({ ...item, count: 0 }));
        break;
      default:
        itemsToSet = [];
    }

    setLocalItems(itemsToSet);
  };

  const handleItemChange = (index, change) => {
    setLocalItems((prevItems) => {
      const updatedItems = [...prevItems];
      const currentItem = updatedItems[index];
      const newCount = currentItem.count + change;

      if (newCount >= 0) {
        updatedItems[index] = { ...currentItem, count: newCount };
      }

      return updatedItems;
    });
  };

  const fetchData = async () => {
    try {
      const enquiryId = localStorage.getItem("enquiry_id");
      const response = await fetch(
        `https://booking.skilledmovers.com.au/api/get-assembly-disembelly?enquiry_id=${enquiryId}`
      );
      const data = await response.json();

      if (data.success) {
        // Define time mappings for different types
        const reassemblyItems = {
          Bed: "5 - 10 minutes",
          Desk: "5 minutes",
          "Dining Table (Large only)": "5 minutes",
          "Baby Cot": "5 minutes",
          "Bunk Bed": "10 - 15 minutes",
          Trampoline: "15 - 20 minutes",
        };

        const disassemblyItems = {
          Bed: "5 minutes",
          Desk: "5 minutes",
          "Dining Table (Large only)": "5 minutes",
          "Baby Cot": "5 minutes",
          "Bunk Bed": "5 - 10 minutes",
          Trampoline: "10 - 15 minutes",
        };

        const bothItems = {
          Bed: "10 - 15 minutes",
          Desk: "5 - 10 minutes",
          "Dining Table (Large only)": "5 - 10 minutes",
          "Baby Cot": "5 - 10 minutes",
          "Bunk Bed": "15 - 25 minutes",
          Trampoline: "20 - 35 minutes",
        };

        // Determine which time mapping to use
        let approxTimeMapping;
        if (assemblyOptiontypes === "reassembly") {
          approxTimeMapping = reassemblyItems;
        } else if (assemblyOptiontypes === "disassembly") {
          approxTimeMapping = disassemblyItems;
        } else if (assemblyOptiontypes === "both") {
          approxTimeMapping = bothItems;
        }

        // Update the items with the correct approximate time
        const updatedItems = data.items.map((item) => {
          const approxTime = approxTimeMapping[item.name] || "Unknown time";

          return {
            ...item,
            count: parseInt(item.quantity),
            approx: approxTime,
          };
        });

        setLocalItems(updatedItems);
      } else {
        console.error("Failed to fetch data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (showLocalModal) {
      fetchData();
      setAssemblyOption(assemblyOptiontypes);
    }
  }, [showLocalModal, assemblyOptiontypes]);

  // useEffect(() => {
  //   if (showLocalModal) {
  //     fetchData();
  //     setAssemblyOption(assemblyOptiontypes);
  //   }
  // }, [showLocalModal, assemblyOptiontypes]);

  const handleCloseLocal = () => setShowLocalModal(false);
  const handleShowLocal = () => setShowLocalModal(true);

  const submitNoThanks = async () => {
    try {
      const enquiryId = localStorage.getItem("enquiry_id");
      if (!enquiryId) {
        console.error("Enquiry ID is missing.");
        return;
      }

      const requestBody = {
        enquiry_id: parseInt(enquiryId, 10),
        assembly_or_disassembly: "",
        total_assemble_dissamble_low_time: 0,
        total_assemble_dissamble_high_time: 0,
      };

      console.log("Posting selected option:", requestBody);

      // Send the POST request and wait for the response
      const response = await fetch(
        `https://booking.skilledmovers.com.au/api/final-quote-update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      ); // <-- await is added here to ensure fetch completes before moving to the next step

      const data = await response.json();

      if (!response.ok) {
        console.error("Response not ok:", data);
        console.error(
          "Failed to post selected option:",
          data.message || "Unknown error"
        );
        return;
      }

      if (!data.success) {
        console.error("Failed to post selected option:", data.message);
      } else {
        console.log("Option posted successfully:", data.message);
      }

      // Clear localStorage for assemblyOptiontypes and localItems
      localStorage.removeItem("assemblyOptiontypes");
      localStorage.removeItem("localItems");

      // Reset the component state
      setAssemblyOptiontypes(null);
      setLocalItems([]);

      // Close the modal after posting is successful
      handleCloseLocal();

      // Reload the page after handling everything
      window.location.reload();
    } catch (error) {
      console.error("Error posting selected option:", error);
    }
  };

  const handleSubmit = async () => {
    await calculateTimes();

    // Store the data in localStorage
    localStorage.setItem(
      "assemblyOptiontypes",
      JSON.stringify(assemblyOptiontypes)
    );
    localStorage.setItem("localItems", JSON.stringify(localItems));

    handleCloseLocal();
    window.location.reload();
  };

  useEffect(() => {
    const storedAssemblyOptiontypes = localStorage.getItem(
      "assemblyOptiontypes"
    );
    const storedLocalItems = localStorage.getItem("localItems");

    if (storedAssemblyOptiontypes !== null) {
      setAssemblyOptiontypes(JSON.parse(storedAssemblyOptiontypes));
    }

    if (storedLocalItems !== null) {
      setLocalItems(JSON.parse(storedLocalItems));
    }
  }, []);

  const calculateTimes = async () => {
    let totalLowTime = 0;
    let totalHighTime = 0;

    localItems.forEach((item) => {
      const approxTimeRange = item.approx.match(/(\d+)\s*-\s*(\d+)/);

      if (approxTimeRange) {
        const minTime = parseInt(approxTimeRange[1]);
        const maxTime = parseInt(approxTimeRange[2]);

        totalLowTime += minTime * item.count;
        totalHighTime += maxTime * item.count;
      } else {
        const singleTime = parseInt(item.approx.match(/\d+/));

        totalLowTime += singleTime * item.count;
        totalHighTime += singleTime * item.count;
      }
    });

    // console.log(`Total Low Time: ${totalLowTime} minutes`);
    // console.log(`Total High Time: ${totalHighTime} minutes`);

    // Post the selected option and calculated times
    await postSelectedOption(totalLowTime, totalHighTime);

    await postAssemblyDisembelly();
  };

  const postSelectedOption = async (totalLowTime, totalHighTime) => {
    try {
      const enquiryId = localStorage.getItem("enquiry_id");
      if (!enquiryId) {
        console.error("Enquiry ID is missing.");
        return;
      }

      const requestBody = {
        enquiry_id: parseInt(enquiryId, 10),
        assembly_or_disassembly: assemblyOption,
        total_assemble_dissamble_low_time: totalLowTime,
        total_assemble_dissamble_high_time: totalHighTime,
      };

      // console.log("Posting selected option:", requestBody);

      const response = await fetch(
        `https://booking.skilledmovers.com.au/api/final-quote-update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (!data.success) {
        console.error("Failed to post selected option:", data.message);
      } else {
        // console.log("Option posted successfully:", data.message);
      }
    } catch (error) {
      console.error("Error posting selected option:", error);
    }
  };

  const postAssemblyDisembelly = async () => {
    try {
      const enquiryId = localStorage.getItem("enquiry_id");
      if (!enquiryId) {
        console.error("Enquiry ID is missing.");
        return;
      }

      // Ensure that localItems contains valid data
      if (!Array.isArray(localItems) || localItems.length === 0) {
        console.error("Local items array is empty or not an array.");
        return;
      }
      const approxTime = localItems.approx;
      // console.log(`Item: ${localItems.name}, Approx Time: ${approxTime}`);
      // Prepare the request body
      const requestBody = {
        enquiry_id: parseInt(enquiryId, 10),
        items: localItems.map((item) => ({
          item_id: item.item_id,
          name: item.name,
          quantity: item.count,
        })),
      };

      // Log the data to be posted
      // console.log("Posting assembly/disassembly data:");
      // console.log(JSON.stringify(requestBody, null, 2));

      // Make the POST request
      const response = await fetch(
        `https://booking.skilledmovers.com.au/api/post-assembly-disembelly`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      // Handle the response
      const data = await response.json();
      if (!data.success) {
        console.error(
          "Failed to post assembly/disassembly data:",
          data.message
        );
      } else {
        console.log(
          "Assembly/disassembly data posted successfully:",
          data.message
        );
      }
    } catch (error) {
      console.error("Error posting assembly/disassembly data:", error);
    }
  };

  const [assemblyOption, setAssemblyOption] = useState("");

  const handleAssemblySelection = (selection) => {
    setShowAssemblyForm(selection === "yes");
  };

  const [items, setItems] = useState({
    bed: 0,
    desk: 0,
    diningTable: 0,
    babyCot: 0,
    bunkBed: 0,
    trampoline: 0,
  });

  const handleDateSelect = (index) => {
    setSelectedDate(index);
  };

  const handleReserveBooking = async () => {
    try {
      const amount = bookingFee;
      const enquiryId = localStorage.getItem("enquiry_id");
      if (!enquiryId) {
        console.error("enquiry_id not found in local storage");
        return; // Exit if enquiry_id is missing
      }
      const response = await fetch(
        "https://booking.skilledmovers.com.au/api/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount,
            enquiryId,
          }),
        }
      );
      // console.log("payment amouunt", amount);
      if (!response.ok) {
        throw new Error("Failed to create checkout session");
      }

      const { id } = await response.json();

      const stripe = window.Stripe(
        "pk_test_51NWXCQSATkn7uWxqrmLHKjcPm3xLmbCJWBnHsEW74CAdV6dv3nUQ88lvBiK64EIgew45a3Ku0o7txrOFn4FRptwR00iEQQtVQk"
      );

      if (!stripe) {
        throw new Error("Stripe.js failed to load");
      }

      const { error } = await stripe.redirectToCheckout({ sessionId: id });

      if (error) {
        console.error("Error redirecting to Checkout:", error);
      }
    } catch (error) {
      console.error("Error in handleReserveBooking:", error);
    }
  };

  const handleClosePickUp = () => setShowPickUpModal(false);
  const handleShowPickUp = () => setShowPickUpModal(true);

  const handleCloseDropOff = () => setShowDropOffModal(false);
  const handleShowDropOff = () => setShowDropOffModal(true);

  const handleCloseAddContact = () => setAddcontact(false);
  const handleshowAddContact = () => setAddcontact(true);

  const handleClosemovingsection = () => setMovingsection(false);
  const handleshowmovingsection = () => setMovingsection(true);

  const handleClosecheapersection = () => setShowcheaper(false);
  const handleshowcheapersection = () => setShowcheaper(true);

  const handleClosereversesection = () => setShowreverse(false);
  const handleshowereversesection = () => setShowreverse(true);

  const handleCloseassemblysection = () => setShowAssembly(false);
  const handleshowassemblysection = () => setShowAssembly(true);

  const handleclosebreakquotesection = () => setBreakdownquote(false);
  const handleshowbreakquotesection = () => setBreakdownquote(truck);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "custom") {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  };

  const [data, setData] = useState(null);
  const [totals, settotals] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingLowTime, setLoadingLowTime] = useState("");
  const [loadingHighTime, setLoadingHighTime] = useState("");
  // Retrieve quoteId from localStorage
  // const quoteId = localStorage.getItem('enquiry_id');
  const [formattedTime, setFormattedTime] = useState("");

  const [enquirys, setEnquirys] = useState({});
  const quoteId = localStorage.getItem("enquiry_id");
  const [pickupLocation, setPickupLocation] = useState(null);
  const [dropoffLocation, setDropoffLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: -34.397, lng: 150.644 });

  const geocodeAddress = async (address) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            address,
            key: "AIzaSyAtUa8t-LE_sdMtpNgvfBSbfE2Sil2cVvw",
          },
        }
      );
      if (response.data.status === "OK") {
        const location = response.data.results[0].geometry.location;
        return location;
      } else {
        throw new Error("Geocoding failed");
      }
    } catch (error) {
      console.error("Geocoding error:", error);
      throw error;
    }
  };

  const [location, setLocation] = useState();
  const [location2, setLocation2] = useState();

  const containerStyle = {
    width: "100%",
    height: "200px",
    position: "relative",
  };

  const iconStyle = {
    position: "absolute",
    color: "red",
    fontSize: "30px",
    transform: "translate(-50%, -100%)",
  };

  useEffect(() => {
    const fromLocation = localStorage.getItem("from_location");
    const toLocation = localStorage.getItem("to_location");

    if (fromLocation) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          fromLocation
        )}&key=AIzaSyAtUa8t-LE_sdMtpNgvfBSbfE2Sil2cVvw`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            setLocation({ lat, lng });
          }
        })
        .catch((error) =>
          console.error("Error fetching from_location:", error)
        );
    }

    if (toLocation) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          toLocation
        )}&key=AIzaSyAtUa8t-LE_sdMtpNgvfBSbfE2Sil2cVvw`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            setLocation2({ lat, lng });
          }
        })
        .catch((error) => console.error("Error fetching to_location:", error));
    }
  }, []);

  useEffect(() => {
    if (quoteId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://booking.skilledmovers.com.au/api/detailed-quote/${quoteId}`
          );
          const enquirydetails = response.data.enquirydetails;
          const totalss = response.data.total;
          settotals(totalss);
          const pickup = enquirydetails.fromlocation;
          const dropoffs = enquirydetails.tolocation;
          const baseTime = enquirydetails.total_duration;
          const typeofassembly_or_disassembly =
            enquirydetails.assembly_or_disassembly;
          setAssemblyOptiontypes(typeofassembly_or_disassembly);
          setGrandTotalLow(enquirydetails.grandTotalLoadingLowTime);
          setGrandTotalMedium(enquirydetails.grandTotalLoadingMediumTime);
          setGrandTotalHigh(enquirydetails.grandTotalLoadingHighTime);
          // console.log(
          //   "/.typeofassembly_or_disassembly",
          //   typeofassembly_or_disassembly
          // );
          // console.log("beasetime", baseTime);

          // Split the time into hours, minutes, and seconds
          const [hours, minutes, seconds] = baseTime.split(":").map(Number);

          // Calculate total time in minutes
          const totalMinutes = hours * 60 + minutes + seconds / 60;

          // Apply the condition
          let finalMinutes;
          if (totalMinutes < 30) {
            finalMinutes = 30; // Set to 30 minutes
          } else {
            finalMinutes = totalMinutes; // Keep the original time
          }

          // Convert finalMinutes back to hours, minutes, and seconds
          const finalHours = Math.floor(finalMinutes / 60);
          const remainingMinutes = Math.floor(finalMinutes % 60);
          const finalSeconds = Math.round((finalMinutes % 1) * 60);

          const formattedTimeString = `${String(finalHours).padStart(
            2,
            "0"
          )}:${String(remainingMinutes).padStart(2, "0")}:${String(
            finalSeconds
          ).padStart(2, "0")}`;

          setFormattedTime(baseTime);

          const pickupLatLng = await geocodeAddress(pickup);
          const dropoffLatLng = await geocodeAddress(dropoffs);

          setPickupLocation(pickupLatLng);
          setDropoffLocation(dropoffLatLng);
          setMapCenter(pickupLatLng);

          setData(enquirydetails);

          console.log("enquirydetails.costPerHour", enquirydetails.costPerHour);
          const costPerMinutesss = enquirydetails.costPerHour / 60;

          console.log(costPerMinutesss);

          setEnquirys(response.data.enquirys);

          setLoadingLowTime(enquirydetails.loadingLowTime);
          setLoadingMediumTime(enquirydetails.loadingMediumTime);
          setLoadingHighTime(enquirydetails.loadingHighTime);

          // Set grand totals
          setGrandTotalLow(enquirydetails.grandTotalLow);
          setGrandTotalMedium(enquirydetails.grandTotalMedium);
          setGrandTotalHigh(enquirydetails.grandTotalHigh);

          setBookingFee(enquirydetails.bookingfee);

          setTotalDurationCostBackToBase(
            enquirydetails.totalDurationCostBackToBase
          );

          setUnLoadingLowTime(enquirydetails.unLoadingLowTime);
          setUnLoadingMediumTime(enquirydetails.unLoadingMediumTime);
          setUnLoadingHighTime(enquirydetails.unLoadingHighTime);

          setFromLocation(enquirydetails.fromlocation);
          setToLocation(enquirydetails.tolocation);

          setTotalDuration(enquirydetails.total_duration);

          setGrandTotalLoadingLowTime(enquirydetails.grandTotalLoadingLowTime);
          setGrandTotalLoadingMediumTime(
            enquirydetails.grandTotalLoadingMediumTime
          );
          setGrandTotalLoadingHighTime(
            enquirydetails.grandTotalLoadingHighTime
          );
          setGrandTotalUnloadingLowTime(
            enquirydetails.grandTotalUnloadingLowTime
          );
          setGrandTotalUnloadingMediumTime(
            enquirydetails.grandTotalUnloadingMediumTime
          );
          setGrandTotalUnloadingHighTime(
            enquirydetails.grandTotalUnloadingHighTime
          );
        } catch (error) {
          setError("Error fetching data");
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    } else {
      setError("No quote ID found in local storage");
      setLoading(false);
    }
  }, [quoteId, setAssemblyOptiontypes]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  // Group items by room and custom item name
  const groupedItems = Object.entries(enquirys).reduce(
    (acc, [roomId, items]) => {
      if (!items || !Array.isArray(items)) {
        console.error(
          `Expected an array for roomId ${roomId}, but got:`,
          items
        );
        return acc;
      }

      items.forEach((item) => {
        if (!acc[roomId]) acc[roomId] = { customItems: {}, roomNames: [] };
        if (!acc[roomId].customItems[item.customItemName])
          acc[roomId].customItems[item.customItemName] = [];
        if (!acc[roomId].roomNames.includes(item.roomName)) {
          acc[roomId].roomNames.push(item.roomName || "Unknown Room");
        }
        acc[roomId].customItems[item.customItemName].push(item.itemName);
      });

      return acc;
    },
    {}
  );
  const convertToSeconds = (time) => {
    const parts = time.split(":");
    if (parts.length === 2) {
      // HH:MM format
      return parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60;
    } else if (parts.length === 3) {
      // HH:MM:SS format
      return (
        parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2])
      );
    }
    return 0;
  };

  // Function to convert seconds back to HH:MM:SS format
  const convertToTimeFormatHHMM = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    seconds %= 3600;
    // console.log("hrs", hrs);

    const mins = Math.floor(seconds / 60);
    // console.log("mins", mins);
    const totalMins = hrs * 60 + mins;
    // console.log("mintotls", totalMins);

    return `${hrs} hr ${mins} min`;
  };

  const totalTime = convertToTimeFormatHHMM(
    convertToSeconds(grandTotalUnloadingMediumTime) +
      convertToSeconds(totalDuration) +
      convertToSeconds(grandTotalLoadingMediumTime)
  );
  const totalTimefirst = convertToTimeFormatHHMM(
    convertToSeconds(grandTotalUnloadingLowTime) +
      convertToSeconds(totalDuration) +
      convertToSeconds(grandTotalLoadingLowTime)
  );
  const totalTimeThird = convertToTimeFormatHHMM(
    convertToSeconds(grandTotalUnloadingHighTime) +
      convertToSeconds(totalDuration) +
      convertToSeconds(grandTotalLoadingHighTime)
  );

  // console.log("...........assemblyOptiontypes......./", assemblyOptiontypes);
  const convertToMinutes = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 60 + minutes + seconds / 60;
  };

  const totalMinutes = convertToMinutes(formattedTime);

  // Conditional display for formattedTime
  const displayMinutes = totalMinutes < 30 ? 30 : formattedTime;
  const enquiryId = localStorage.getItem("enquiry_id");
  return (
    <div className="access-properties properties__date">
      <div className="container">
        <div
          className="nav d-flex justify-content-around"
          role="tablist"
          aria-orientation="vertical"
        >
          <a className="nav-link ">Location</a>
          <a className="nav-link">Move Size</a>
          <a className="nav-link ">Date & Time</a>
          <a className="nav-link ">Details</a>
          <a className="nav-link active">My Quote</a>
        </div>
        <div>
          <h3 className="text-center">Here’s your Quote</h3>
          <div className="tabs">
            <button
              className={`myquote_tab ${
                activeTab === "summary" ? "active" : ""
              }`}
              onClick={() => setActiveTab("summary")}
            >
              Quote Summary
            </button>
            <button
              className={`myquote_tab ${
                activeTab === "inventory" ? "active" : ""
              }`}
              onClick={() => setActiveTab("inventory")}
            >
              Inventory
            </button>
            <button
              className={`myquote_tab ${
                activeTab === "photos" ? "active" : ""
              }`}
              onClick={() => setActiveTab("photos")}
            >
              Photos (Optional)
            </button>
          </div>
          {activeTab === "summary" && (
            <div className="bg__myquote">
              <div className="Myquote_booking">
                <p className="mb-0 text-white fs-5 font-weight-bold">
                  {/* Quote 39537 */}
                </p>
                <button
                  className="btn reserve__booking"
                  onClick={handleReserveBooking}
                >
                  Reserve My Booking
                </button>
              </div>

              <div className="join_myquote pt-4">
                <h2>
                  Join the over 4,000 individuals we've assisted with their
                  moves this year.
                </h2>
                <p>
                  You can be completely confident in having a great move with...
                </p>
                <div className="features">
                  <div className="feature">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="check-icon"
                    />
                    <div className="feature-text">
                      <h3>Expert Movers</h3>
                      <p>Swift, professional, and user-friendly.</p>
                    </div>
                  </div>
                  <div className="feature">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="check-icon"
                    />
                    <div className="feature-text">
                      <h3>Transparent pricing</h3>
                      <p>Clear pricing, no hidden fees.</p>
                    </div>
                  </div>
                  <div className="feature">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="check-icon"
                    />
                    <div className="feature-text">
                      <h3>Protection insurance</h3>
                      <p>Comprehensive damage and liability coverage</p>
                    </div>
                  </div>
                  <div className="feature">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="check-icon"
                    />
                    <div className="feature-text">
                      <h3>Just AUD 30</h3>
                      <p>Book now, pay AUD 30 today</p>
                    </div>
                  </div>
                  <div className="feature">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="check-icon"
                    />
                    <div className="feature-text">
                      <h3>Cancel freely</h3>
                      <p>Reschedule or cancel with ease.</p>
                    </div>
                  </div>
                  <div className="feature">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="check-icon"
                    />
                    <div className="feature-text">
                      <h3>Guaranteed satisfaction</h3>
                      <p>10% off if you're unsatisfied</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="pt-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="fs-5 font-weight-bold mb-0">Contact</h6>
                  <a
                    onClick={handleshowAddContact}
                    className="text-decoration-none"
                  >
                    + Add Contact
                  </a>
                </div>
                <div className="pt-4 d-flex  align-items-center justify-content-between fs-5 contact__details">
                  <div>
                    <p className="mb-0"> {data.customer_name}</p>
                    <p className="mb-0">Booker - On Site</p>
                  </div>
                  <div className="pt-2 pt-md-0">
                    <p className="mb-0"> {data.customer_phone}</p>
                    <p className="mb-0 inverntory_customer_email">
                      {" "}
                      {data.customer_email}
                    </p>
                  </div>
                  <div className="pt-2 pt-md-0">
                    <p className="mb-0">Primary contact</p>
                    <p className="mb-0">SMS Notifications ON</p>
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between fs-5">
                  <div className="col-md-6">
                    <h6 className="fs-5 font-weight-bold mb-0">Pick Up</h6>
                    <p className="mb-0">
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        style={{ marginRight: "10px", paddingTop: "2%" }}
                      />
                      {data.fromlocation}{" "}
                    </p>
                    <p className="pt-2">Single level home.</p>
                    <p>Truck access via driveway.</p>
                    {/* <p>
                      <i>dc</i>
                    </p> */}
                  </div>
                  <div className="col-md-6">
                    <div className="row ">
                      <div className="col-md-6 ">
                        {/* <LoadScript googleMapsApiKey="AIzaSyAtUa8t-LE_sdMtpNgvfBSbfE2Sil2cVvw"> */}
                        {location && (
                          <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={location}
                            zoom={15}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: `${location.lat}%`,
                                left: `${location.lng}%`,
                                transform: "translate(-50%, -50%)",
                                zIndex: 1,
                              }}
                            >
                              <Marker
                                position={location}
                                icon={faMapMarkerAlt}
                                style={iconStyle}
                              />
                            </div>
                          </GoogleMap>
                        )}
                        {/* </LoadScript> */}
                      </div>
                      <div className="col-md-6 align-content-center mt-2 mt-sm-0">
                        <GoogleImage />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between fs-5">
                  <div className="col-md-6">
                    <h6 className="fs-5 font-weight-bold mb-0">Drop Off</h6>
                    <p className="mb-0">
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        style={{ marginRight: "10px", paddingTop: "2%" }}
                      />
                      {data.tolocation}
                    </p>
                    <p className="pt-2">Single level home.</p>
                    <p>Truck access via driveway.</p>
                    {/* <p>
                      <i>dc</i>
                    </p> */}
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6">
                        {location2 && (
                          <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={location2}
                            zoom={15}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: `${location2.lat}%`,
                                left: `${location2.lng}%`,
                                transform: "translate(-50%, -50%)",
                                zIndex: 1,
                              }}
                            >
                              <Marker
                                position={location2}
                                icon={faMapMarkerAlt}
                                style={iconStyle}
                              />
                            </div>
                          </GoogleMap>
                        )}
                      </div>
                      <div className="col-md-6 align-content-center mt-2 mt-sm-0">
                        <GoogleToImage />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h6 className="fs-5 font-weight-bold mb-0">Moving Services</h6>
                <div className="d-flex justify-content-between fs-5 contact__details">
                  <div className="col-md-6">
                    <p className="mb-1">When :</p>
                    {/* <p className="mb-0">{data.pickup_date}</p>{" "} */}
                    <p className="mb-0">
                      {format(new Date(data.pickup_date), "dd-MM-yyyy")}
                    </p>

                    <span>
                      {/* <a
                        onClick={handleshowcheapersection}
                        className="text-decoration-none"
                      >
                        Cheaper day available (Save ~ $25 )
                      </a> */}
                    </span>
                    <p className="pt-2">Estimated Moving Time:</p>
                    <p>
                      {totalTimefirst} to {totalTimeThird}{" "}
                      <span className="solid_quote">
                        (2 hour minimum applied)
                      </span>
                    </p>
                    <p>
                      <span className="solid_quote">
                        + 30 minutes back to base
                      </span>
                    </p>
                    {/* <p>Your Team:</p> */}
                    <p>
                      {data.moversCount}
                      <span className="solid_quote">
                        {" "}
                        Removalists + Head office support
                      </span>
                    </p>
                    <div>
                      {/* <img src={team} alt="team" className="quote_image" /> */}
                    </div>
                    <p>Rate:</p>
                    <p className="fs-6 solid_quote">
                      AUD {data.costPerHour} / hour inc GST{" "}
                      <span className="minimum"> (2 hour minimum)</span>
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p className="mb-1">Arrival Time</p>
                    <p className="mb-0">{data.arrive_on} </p>
                    <span>
                      {/* <a
                        onClick={handleshowmovingsection}
                        className="text-decoration-none"
                      >
                        Change Moving date
                      </a> */}
                    </span>
                    <p className="pt-2">Estimated Move Size:</p>
                    <p>
                      {data.totalSelectedVolume}m<sup>3</sup>{" "}
                    </p>
                    <p>
                      <span className="solid_quote">
                        Volume based on customer’s inventory
                      </span>
                    </p>
                    <p>Your Truck:</p>
                    <p>{data.totalVehicles} Truck</p>
                    <div>
                      {/* <img src={truck} alt="truck" className="quote_image" /> */}
                    </div>
                    <p className="mb-1">
                      Furniture:{" "}
                      <span className="font-weight-bold">
                        {assemblyOptiontypes ? assemblyOptiontypes : "N/A"}
                      </span>
                    </p>

                    {/* <p className="mb-0">Not requested</p> */}
                    <Button
                      onClick={handleShowLocal}
                      style={{ backgroundColor: "#ff5c00", border: "#ff5c00" }}
                    >
                      Update Assembly / Disassembly
                    </Button>
                    {/* single row added by shubham */}
                    {assemblyOptiontypes !== "null" &&
                      localItems.some((item) => item.count > 0) && (
                        <p className="mt-3">
                          {localItems
                            .filter((item) => item.count > 0)
                            .map((item) => `${item.count} ${item.name}`)
                            .join(", ")}
                        </p>
                      )}

                    {/* ================== */}

                    <Modal show={showLocalModal} onHide={handleCloseLocal}>
                      <Modal.Header>
                        <Modal.Title> My Quote</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group
                          style={{
                            borderWidth: 1,
                            borderColor: "#d0d5dd",
                            borderStyle: "solid",
                            padding: 10,
                            marginBottom: 10,
                            borderRadius: "8px",
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <Form.Check
                              type="radio"
                              label="Yes Please"
                              value="yes"
                              checked={
                                yesPleaseSelected === true &&
                                ["both", "reassembly", "disassembly"].includes(
                                  assemblyOptiontypes
                                )
                              }
                              onChange={handleYesNoChange}
                              className="custom-radio"
                            />
                            <Form.Check
                              type="radio"
                              label="No Thanks"
                              value="no"
                              checked={
                                yesPleaseSelected === false ||
                                assemblyOptiontypes === "null"
                              }
                              onChange={handleYesNoChange}
                              className="custom-radio"
                              style={{ margin: "10px" }}
                            />
                          </div>
                        </Form.Group>

                        {yesPleaseSelected && (
                          <>
                            <div
                              style={{
                                // borderWidth: 1,
                                // borderColor: "black",
                                // borderStyle: "solid",
                                padding: 12,
                                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                borderRadius: "8px",
                              }}
                            >
                              <Form.Group
                                controlId="assemblyOption"
                                className="my-3"
                              >
                                <Form.Label>
                                  Would you like both Disassembly & Reassembly?
                                </Form.Label>
                                <div className="d-flex justify-content-between align-items-center">
                                  <Form.Check
                                    type="radio"
                                    label="Yes, both"
                                    value="both"
                                    checked={assemblyOption === "both"}
                                    onChange={handleAssemblyOptionChange}
                                    className="custom-radio"
                                  />
                                  <Form.Check
                                    type="radio"
                                    label="Disassembly only"
                                    value="disassembly"
                                    checked={assemblyOption === "disassembly"}
                                    onChange={handleAssemblyOptionChange}
                                    className="custom-radio"
                                  />
                                  <Form.Check
                                    type="radio"
                                    label="Reassembly only"
                                    value="reassembly"
                                    checked={assemblyOption === "reassembly"}
                                    onChange={handleAssemblyOptionChange}
                                    className="custom-radio"
                                  />
                                </div>
                                <span className="text-danger">
                                  * Please select one of the above
                                </span>
                              </Form.Group>
                              <hr />
                              <ul className="p-0">
                                {localItems.map((item, index) => (
                                  <li
                                    key={item.id}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      padding: "8px 0",
                                      borderBottom: "1px solid #ddd",
                                    }}
                                  >
                                    <div>
                                      <span style={{ fontWeight: "bold" }}>
                                        {item.name}
                                      </span>
                                      <div>
                                        <span
                                          style={{
                                            fontStyle: "italic",
                                            // marginLeft: "10px",
                                            color: "#d0d5dd",
                                          }}
                                        >
                                          (Approx {item.approx})
                                        </span>
                                      </div>
                                    </div>
                                    <div className="item-controls d-flex align-items-center">
                                      <Button
                                        variant=" "
                                        className="room-button"
                                        onClick={() =>
                                          handleItemChange(index, -1)
                                        }
                                        disabled={item.count <= 0}
                                      >
                                        -
                                      </Button>
                                      <span
                                        className="p-2 text-center"
                                        style={{
                                          minWidth: "40px",
                                          display: "inline-block",
                                        }}
                                      >
                                        {item.count}
                                      </span>
                                      <Button
                                        variant=" "
                                        className="room-button"
                                        onClick={() =>
                                          handleItemChange(index, 1)
                                        }
                                      >
                                        +
                                      </Button>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant=" "
                          onClick={handleCloseLocal}
                          style={{ background: "#ff5c00", color: "white" }}
                        >
                          Close
                        </Button>
                        <Button
                          variant=" "
                          style={{ background: "#ff5c00", color: "white" }}
                          onClick={() => {
                            if (yesPleaseSelected === false) {
                              submitNoThanks(); // Call the submitNoThanks function
                              // console.log("No Thanks selected");
                            } else {
                              handleSubmit(); // Call the existing handleSubmit function
                            }
                          }}
                        >
                          Submit
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
              <hr />
              <div className="estimated_time_cost_total_myquote">
                <h3 class="text-start">Estimated Time & Cost</h3>
                <span className="details text-start fs-5"></span>
                <span className="details text-start fs-5">
                  AUD {grandTotalLow} / hour inc GST{" "}
                </span>

                <div class="pricing-table my-5">
                  <div class="table">
                    <div class="table-row header">
                      <div class="table-cell"></div>
                      <div class="table-cell">
                        Low
                        <br />
                        <span>Well Packed + Actively Help</span>
                      </div>
                      <div class="table-cell">
                        Medium
                        <br />
                        <span>Well Packed + Leave it to Us</span>
                      </div>
                      <div class="table-cell">
                        High
                        <br />
                        <span>Bigger than expected</span>
                      </div>
                    </div>
                    <div class="table-row">
                      <div class="table-cell">Packing</div>
                      <div class="table-cell">Everything packed in boxes</div>
                      <div class="table-cell">Everything packed in boxes</div>
                      <div class="table-cell">Items not well packed</div>
                    </div>
                    <div class="table-row">
                      <div class="table-cell">Access to properties</div>
                      <div class="table-cell">As expected or better</div>
                      <div class="table-cell">As expected or better</div>
                      <div class="table-cell">More difficult than expected</div>
                    </div>
                    <div class="table-row">
                      <div class="table-cell">Your assistance on the day</div>
                      <div class="table-cell">
                        Actively Assistance with small items
                      </div>
                      <div class="table-cell">Leave it to us</div>
                      <div class="table-cell">Leave it to us</div>
                    </div>
                    <div class="table-row">
                      <div class="table-cell">Number of fragile items</div>
                      <div class="table-cell">A few</div>
                      <div class="table-cell">Normal</div>
                      <div class="table-cell">Many</div>
                    </div>
                    <div class="table-row footer">
                      <div class="table-cell text-black">Estimated Time</div>
                      <div class="table-cell text-black">{totalTimefirst}</div>
                      <div class="table-cell text-black"> {totalTime} </div>
                      <div class="table-cell text-black">{totalTimeThird}</div>
                    </div>
                    <div class="table-row footer">
                      <div class="table-cell text-black">Estimated Cost</div>
                      <div class="table-cell text-black">
                        {" "}
                        AUD {grandTotalLow}
                      </div>
                      <div class="table-cell text-black">
                        {" "}
                        AUD {grandTotalMedium}
                      </div>
                      <div class="table-cell text-black">
                        {" "}
                        AUD {grandTotalHigh}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="highlight">
                  <span className="highlight-detail fs-5">
                    The minimum charge is 2 hours, with an additional 30 minutes
                    for return to base.
                  </span>
                  <h2>
                    AUD {grandTotalLow} <span>({totalTimefirst})</span>
                  </h2>
                  <span
                    className="highlight-detail fs-5"
                    style={{ color: "#bfc3f7" }}
                  >
                    For the best value, we can assist with additional moving or
                    assembly services.
                  </span>
                </div>
                <span className="note fs-5">
                  This estimate is calculated based on an <u>hourly rate</u>.
                  <br />
                  The estimate is based on insights from over 10,000 moves and
                  the information you provide.
                  <br />
                  The final cost may vary depending on the actual work
                  completed.
                </span>

                <a
                  className="breakdown fs-5"
                  onClick={handleshowbreakquotesection}
                  style={{ color: "#ff5c00" }}
                >
                  Detailed Breakdown of Moving Costs
                </a>
              </div>

              <div className="col-md-12 pt-4 d-flex justify-content-center ">
                <button
                  className="btn reserve__booking p-2 fs-5 font-weight-normal"
                  onClick={handleReserveBooking}
                >
                  Reserve My Booking AUD {bookingFee} (Fully refundable)
                </button>
              </div>
              <div class="contact-section pt-3">
                <h5 class="contact-title" style={{ color: "#ff5c00" }}>
                  Got Questions?
                </h5>
                <div class="contact-options">
                  <div class="contact-box">
                    <a href="tel: +03 8907 0486" class="contact-button_myquote">
                      Call us on +03 8907 0486
                    </a>
                    <p class="contact-hours">Monday to Saturday, 8 - 5</p>
                  </div>
                  <span class="contact-or">or</span>
                  <div class="contact-box">
                    <a href="sms: +0450 383 690" class="contact-button_myquote">
                      SMS +0450 383 690
                    </a>
                    <p class="contact-hours">7 days till late</p>
                  </div>
                </div>
                <p class="contact-note fs-5">
                  We guarantee 100% peace of mind. Changed your mind? No
                  problem—your booking fee is fully refundable.
                </p>
              </div>

              <hr />

              <div class="savings-section">
                <h3 class="savings-title text-start">
                  3 Simple Ways to Save...
                </h3>
                <p class="savings-description text-start fs-5">
                  We're happy to take care of everything, but if you're looking
                  to save, here’s how...
                </p>
                <div class="savings-options row">
                  <div class="savings-box col-12 col-md-4 mb-3 mb-md-0">
                    <div class="savings-number">①</div>
                    <div class="savings-content">
                      <h4 class="savings-heading">
                        Pack and prepare in advance.
                      </h4>
                      <p class="savings-detail">
                        Box, label, and arrange items to reduce walking time.
                      </p>
                    </div>
                  </div>
                  <div class="savings-box col-12 col-md-4 mb-3 mb-md-0">
                    <div class="savings-number">②</div>
                    <div class="savings-content">
                      <h4 class="savings-heading">
                        Assistance with small items
                      </h4>
                      <p class="savings-detail">
                        We'll handle all the heavy lifting, but if you assist
                        with small items, you'll save significantly.
                      </p>
                    </div>
                  </div>
                  <div class="savings-box col-12 col-md-4">
                    <div class="savings-number">③</div>
                    <div class="savings-content">
                      <h4 class="savings-heading">
                        Provide us with a time estimate.
                      </h4>
                      <p class="savings-detail">
                        On a tight budget? Let's discuss working within specific
                        timeframes.
                      </p>
                    </div>
                  </div>
                </div>
                <p class="savings-note fs-5">
                  Keep in mind that with Skilled Movers, you only pay for the
                  time you actually use.
                </p>
              </div>
              <div className="col-md-12 pt-4 d-flex justify-content-center ">
                <button
                  className="btn reserve__booking  p-2 fs-5 font-weight-normal"
                  onClick={handleReserveBooking}
                >
                  Reserve My Booking AUD {bookingFee} (Fully refundable)
                </button>
              </div>
            </div>
          )}
          {activeTab === "inventory" && (
            <div className="bg__myquote">
              <Inventory />
            </div>
          )}
          {activeTab === "photos" && (
            <div className="bg__myquote">
              <Photo />
            </div>
          )}

          {/* Pick Up Address Modal */}
          <Modal show={showPickUpModal} onHide={handleClosePickUp} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <img
                  src={delivery}
                  alt="Pick Up Logo"
                  style={{ width: "50px", marginRight: "10px" }}
                />
                Pick Up Address
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="pickUpAddress">
                  <Form.Label>Street Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={1}
                    placeholder="Enter address"
                  />
                </Form.Group>
                <Form.Group controlId="pickUpMap">
                  <Form.Label>Map</Form.Label>
                  {/* Replace with actual map component or placeholder */}
                  <div
                    style={{
                      height: "200px",
                      backgroundColor: "#f0f0f0",
                      marginTop: "10px",
                    }}
                  >
                    <GoogleMapComponent />
                  </div>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                onClick={handleClosePickUp}
                className="map_done"
              >
                Done
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Drop Off Address Modal */}
          <Modal show={showDropOffModal} onHide={handleCloseDropOff} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <img
                  src={dropoff}
                  alt="Drop Off Logo"
                  style={{ width: "50px", marginRight: "10px" }}
                />
                Drop Off Address
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="dropOffAddress">
                  <Form.Label>Street Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={1}
                    placeholder="Enter address"
                  />
                </Form.Group>
                <Form.Group controlId="dropOffMap">
                  <Form.Label>Map</Form.Label>
                  {/* Replace with actual map component or placeholder */}
                  <div
                    style={{
                      height: "200px",
                      backgroundColor: "#f0f0f0",
                      marginTop: "10px",
                    }}
                  >
                    <GoogleMapComponent />
                  </div>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleCloseDropOff}
                className="map_done"
              >
                Done
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Add Contact */}
          <Modal show={showaddcontact} onHide={handleCloseAddContact} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <img
                  src={contact}
                  alt="Pick Up Logo"
                  style={{ width: "50px", marginRight: "10px" }}
                />
                Add Contact
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Button
                  variant={
                    activeSection === "onsite" ? "primary" : "outline-primary"
                  }
                  className="contact_profile w-50 rounded-0"
                  onClick={() => setActiveSection("onsite")}
                >
                  On Site
                </Button>
                <Button
                  variant={
                    activeSection === "offsite" ? "primary" : "outline-primary"
                  }
                  className="contact_profile w-50 rounded-0"
                  onClick={() => setActiveSection("offsite")}
                >
                  Off Site
                </Button>
              </div>
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group controlId="firstName">
                      <Form.Label>
                        <span className="text-danger">*</span>First Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter first name"
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter last name" />
                    </Form.Group>
                  </div>
                </div>
                <Form.Group controlId="relationship">
                  <Form.Label>
                    <span className="text-danger">*</span>Relationship
                  </Form.Label>
                  <Form.Control type="text" placeholder="Enter relationship" />
                </Form.Group>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group controlId="phoneNumber">
                      <Form.Label>
                        <span className="text-danger">*</span>Phone Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter phone number"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" />
                    </Form.Group>
                  </div>
                </div>
                <Form.Group controlId="role">
                  <Form.Label>
                    <span className="text-danger">*</span>Role
                  </Form.Label>
                  <Form.Control as="select">
                    <option>Select role</option>
                    <option>Primary Contact</option>
                    <option>Access</option>
                    <option>Moving Help</option>
                    <option>Payment Assistance</option>
                    <option>Other</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="checkbox">
                  <Form.Check
                    type="checkbox"
                    label="Receives SMS notifications"
                    className="mt-3"
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="cancel_myquote"
                onClick={handleCloseAddContact}
              >
                Cancel
              </Button>
              <Button variant="secondary" className="okay_myquote">
                OK
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Change moving Time */}
          <Modal
            show={movingsection}
            onHide={handleClosemovingsection}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <img
                  src={time}
                  alt="Pick Up Logo"
                  style={{ width: "50px", marginRight: "10px" }}
                />
                Change Moving Time
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form className="mt-0">
                <div className="d-flex justify-content-between">
                  <Form.Check
                    type="radio"
                    label="Default Time"
                    name="movingTime"
                    value="default"
                    checked={selectedOption === "default"}
                    onChange={handleRadioChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Morning"
                    name="movingTime"
                    value="morning"
                    checked={selectedOption === "morning"}
                    onChange={handleRadioChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Custom"
                    name="movingTime"
                    value="custom"
                    checked={selectedOption === "custom"}
                    onChange={handleRadioChange}
                  />
                </div>
                {showAlert && (
                  <Alert variant="warning" className="mt-3">
                    <h5 className="fs-6">
                      Standard morning or afternoon bookings preferred.
                    </h5>
                    <p
                      className="text-justify"
                      style={{ textAlign: "justify" }}
                    >
                      Working to a deadline e.g. property settlement or lift
                      booking? No problems, we’ll work with you.
                    </p>
                  </Alert>
                )}
                {showAlert && (
                  <Form.Group controlId="customTime" className="mt-3">
                    <Form.Label>
                      <span className="text-danger">*</span>Custom Time
                    </Form.Label>
                    <Form.Control as="select">
                      <option value="8:00">8:00 AM</option>
                      <option value="9:00">9:00 AM</option>
                      <option value="10:00">10:00 AM</option>
                      <option value="11:00">11:00 AM</option>
                      <option value="12:00">12:00 PM</option>
                      <option value="1:00">1:00 PM</option>
                      <option value="2:00">2:00 PM</option>
                      <option value="3:00">3:00 PM</option>
                      <option value="4:00">4:00 PM</option>
                    </Form.Control>
                  </Form.Group>
                )}
                {showAlert && (
                  <Form.Group controlId="messageBox" className="mt-3">
                    <Form.Label>
                      <span className="text-danger">*</span>Tell us why it’s
                      important to move at this time
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter your message here"
                    />
                  </Form.Group>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="cancel_myquote"
                onClick={handleClosemovingsection}
              >
                Cancel
              </Button>
              <Button variant="" className="map_done">
                Update
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Cheaper */}
          <Modal show={showcheaper} onHide={handleClosecheapersection} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <img
                  src={myquotetime}
                  alt="Pick Up Logo"
                  style={{ width: "50px", marginRight: "10px" }}
                />
                Select a new moving date
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="unique_cheaper_time">
                <Row>
                  {dateData.map((date, index) => (
                    <Col key={index} xs={6} md={3} className="mb-3">
                      <div
                        className={`unique_cheaper_date_box ${
                          selectedDate === index ? "unique_selected" : ""
                        }`}
                        onClick={() => handleDateSelect(index)}
                      >
                        <div className="text-center">
                          <div className="unique_date_day">{date.day}</div>
                          <div className="unique_date_date">{date.date}</div>
                          <div className="unique_date_price">{date.price}</div>
                          {date.additional && (
                            <div className="text-danger unique_date_additional">
                              {date.additional}
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Button
                  variant="outline-primary"
                  className="mx-2 cancel_myquote"
                >
                  Earlier
                </Button>
                <Button
                  variant="outline-primary"
                  className="mx-2 cancel_myquote"
                >
                  Later
                </Button>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="map_done"
                onClick={handleClosecheapersection}
              >
                Review my quote
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Assembly/Dissembly */}
          <Modal
            show={showAssembly}
            onHide={handleCloseassemblysection}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <img
                  src={hammer}
                  alt="Pick Up Logo"
                  style={{ width: "50px", marginRight: "10px" }}
                />
                Add Assembly or Disassembly
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>
                  No additional fees, just adds to the moving time. Change your
                  mind anytime.
                </p>
                <div className="d-flex align-items-center justify-content-center assembly_from">
                  <div className="col-md-4">
                    <label className="radio-label mb-0">
                      <input
                        type="radio"
                        id="house"
                        name="propertyType"
                        onClick={() => handleAssemblySelection("yes")}
                      />
                      Yes Please
                    </label>
                  </div>
                  <div className="col-md-4">
                    <label className="radio-label mb-0">
                      <input
                        type="radio"
                        id="house"
                        name="propertyType"
                        onClick={() => handleAssemblySelection("no")}
                      />
                      No, Thanks
                    </label>
                  </div>
                </div>
                {showAssemblyForm && (
                  <>
                    <div className="select-items assembly_bg mt-2">
                      <h5>Select your items</h5>
                      {[
                        { name: "Bed", approx: "10 - 15 minutes", key: "bed" },
                        { name: "Desk", approx: "5 - 10 minutes", key: "desk" },
                        {
                          name: "Dining Table",
                          approx: "5 - 10 minutes",
                          key: "diningTable",
                        },
                        {
                          name: "Baby Cot",
                          approx: "5 - 10 minutes",
                          key: "babyCot",
                        },
                        {
                          name: "Bunk Bed",
                          approx: "15 - 25 minutes",
                          key: "bunkBed",
                        },
                        {
                          name: "Trampoline",
                          approx: "20 - 35 minutes",
                          key: "trampoline",
                        },
                      ].map((item) => (
                        <div key={item.key} className="item-row">
                          <div>
                            <span>{item.name}</span>
                          </div>
                          <div></div>
                          <div className="item-controls">
                            <Button
                              variant="outline-primary"
                              onClick={() => handleItemChange(item.key, -1)}
                            >
                              -
                            </Button>
                            <span className="p-2">{items[item.key]}</span>
                            <Button
                              variant="outline-primary"
                              onClick={() => handleItemChange(item.key, 1)}
                            >
                              +
                            </Button>
                          </div>
                        </div>
                      ))}
                      <Form.Group controlId="assemblyOption" className="mt-3">
                        <Form.Label>
                          Would you like both Disassembly & Reassembly?
                        </Form.Label>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Check
                            type="radio"
                            label="Yes, both"
                            value="both"
                            checked={assemblyOption === "both"}
                            onChange={handleAssemblyOptionChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Disassembly only"
                            value="disassembly"
                            checked={assemblyOption === "disassembly"}
                            onChange={handleAssemblyOptionChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Reassembly only"
                            value="reassembly"
                            checked={assemblyOption === "reassembly"}
                            onChange={handleAssemblyOptionChange}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="map_done"
                onClick={handleCloseassemblysection}
              >
                Cancel
              </Button>
              <Button variant="" className="map_done ">
                Update
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Breakdown Model */}
          <Modal
            show={breakdownquote}
            onHide={handleclosebreakquotesection}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Detailed Breakdown of Moving Costs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* new table added */}
              <div class="pricing-table">
                <div class="table">
                  <div class="table-row header">
                    <div class="table-cell"></div>
                    <div class="table-cell">
                      Low
                      <br />
                      <span>Well Packed + Actively Help</span>
                    </div>
                    <div class="table-cell">
                      Medium
                      <br />
                      <span>Well Packed + Leave it to Us</span>
                    </div>
                    <div class="table-cell">
                      High
                      <br />
                      <span>Bigger than expected</span>
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="table-cell">Packing</div>
                    <div class="table-cell">Everything packed in boxes</div>
                    <div class="table-cell">Everything packed in boxes</div>
                    <div class="table-cell">Items not well packed</div>
                  </div>
                  <div class="table-row">
                    <div class="table-cell">Access to properties</div>
                    <div class="table-cell">As expected or better</div>
                    <div class="table-cell">As expected or better</div>
                    <div class="table-cell">More difficult than expected</div>
                  </div>
                  <div class="table-row">
                    <div class="table-cell">Your assistance on the day</div>
                    <div class="table-cell">
                      Actively Assistance with small items
                    </div>
                    <div class="table-cell">Leave it to us</div>
                    <div class="table-cell">Leave it to us</div>
                  </div>
                  <div class="table-row">
                    <div class="table-cell">Number of fragile items</div>
                    <div class="table-cell">A few</div>
                    <div class="table-cell">Normal</div>
                    <div class="table-cell">Many</div>
                  </div>
                  <div class="table-row footer">
                    <div class="table-cell text-black">Estimated Time</div>
                    <div class="table-cell text-black">{totalTimefirst}</div>
                    <div class="table-cell text-black"> {totalTime} </div>
                    <div class="table-cell text-black">{totalTimeThird}</div>
                  </div>
                  <div class="table-row footer">
                    <div class="table-cell text-black">Estimated Cost</div>
                    <div class="table-cell text-black">
                      {" "}
                      AUD {grandTotalLow}
                    </div>
                    <div class="table-cell text-black">
                      {" "}
                      AUD {grandTotalMedium}
                    </div>
                    <div class="table-cell text-black">
                      {" "}
                      AUD {grandTotalHigh}
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-dialog modal-lg breakdown__data">
                <div className="modal-content  border-0">
                  <table className="table table-borderless table__data ">
                    <thead>
                      <tr>
                        <th className="text-center">
                          <span className="small font-weight-bold">
                            All rates and costs incl GST
                          </span>
                        </th>
                        <th className="text-center">Low</th>
                        <th className="text-center text-light">Medium</th>
                        <th className="text-center">High</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Rows */}
                      <tr>
                        <td rowSpan={2}>
                          <span className="font-weight-bold">
                            Hourly rate items
                          </span>{" "}
                          <br />
                          <span className="small">
                            <span className="small">
                              {data.totalVehicles} truck + {data.moversCount}{" "}
                              movers @ {data.costPerHour} / hr
                            </span>
                            <br />
                            Loading{" "}
                            <span style={{ fontSize: "12px" }}>
                              (
                              {assemblyOptiontypes
                                ? assemblyOptiontypes
                                : "N/A"}
                              )
                            </span>
                          </span>
                        </td>
                        <td className="text-center" />
                        <td className="text-center my__quote__tabledata" />
                        <td className="text-center" />
                      </tr>
                      <tr>
                        <td className="text-center">
                          {grandTotalLoadingLowTime} hour
                        </td>
                        <td className="text-center my__quote__tabledata">
                          {grandTotalLoadingMediumTime} hour
                        </td>
                        <td className="text-center">
                          {grandTotalLoadingHighTime} hour
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Transit <br />{" "}
                          <span className="small text-muted">
                            {fromLocation} - <br /> {toLocation}
                          </span>
                        </td>
                        <td className="text-center">{formattedTime} minutes</td>
                        <td className="text-center my__quote__tabledata">
                          {formattedTime} minutes
                        </td>
                        <td className="text-center">{formattedTime} minutes</td>
                      </tr>
                      <tr>
                        <td>
                          Unloading <br />
                          <span style={{ fontSize: "12px" }}>
                            ({assemblyOptiontypes ? assemblyOptiontypes : "N/A"}
                            )
                          </span>
                        </td>
                        <td className="text-center">
                          {grandTotalUnloadingLowTime} Hour
                        </td>
                        <td className="text-center my__quote__tabledata">
                          {grandTotalUnloadingMediumTime} Hour
                        </td>
                        <td className="text-center">
                          {grandTotalUnloadingHighTime} Hour
                        </td>
                      </tr>
                      {/* <tr>
                          <td>Total</td>
                          <td className="text-center">
                            <span className="font-weight-bold">2:00 hours</span>
                            <br />
                            $358.00
                          </td>
                          <td className="text-center my__quote__tabledata">
                            <span className="font-weight-bold">2:00 hours</span>
                            <br />
                            $358.00
                          </td>
                          <td className="text-center">
                            <span className="font-weight-bold">2:00 hours</span>
                            <br />
                            $358.00
                          </td>
                        </tr> */}
                      <tr>
                        <td>
                          <span className="font-weight-bold">
                            Fixed price items
                          </span>
                          <br />
                          <span className="small">
                            Back to base
                            <br />
                            <span className="text-muted small">
                              {displayMinutes} minutes @ {data.costPerHour}
                              hour
                            </span>
                          </span>
                        </td>
                        <td className="text-center">
                          AUD {totalDurationCostBackToBase}
                        </td>
                        <td className="text-center my__quote__tabledata">
                          AUD {totalDurationCostBackToBase}
                        </td>
                        <td className="text-center">
                          AUD {totalDurationCostBackToBase}
                        </td>
                      </tr>
                      <tr>
                        <td>Booking fee</td>
                        <td className="text-center">AUD {bookingFee}</td>
                        <td className="text-center my__quote__tabledata">
                          AUD {bookingFee}
                        </td>
                        <td className="text-center">AUD {bookingFee}</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="font-weight-bold">
                            Total est. cost
                          </span>
                          <br />
                          <span>Incl. GST</span>
                        </td>
                        <td className="text-center total-estimate">
                          AUD <br /> {grandTotalLow}
                        </td>
                        <td
                          className="text-center font-weight-bold"
                          style={{ background: "#ff5c00", color: "white" }}
                        >
                          AUD <br /> {grandTotalMedium}
                        </td>
                        <td className="text-center">
                          AUD <br /> {grandTotalHigh}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center">
              <button
                className="btn reserve__booking p-2 fs-5"
                onClick={() => {
                  handleshowereversesection();
                  handleclosebreakquotesection();
                  handleReserveBooking();
                }}
              >
                Reserve My Booking AUD 30 (Fully refundable)
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default InventoryMyquote;
