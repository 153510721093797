import React, { useEffect, useState, useRef, useCallback } from "react";
import "../Localremovalists/Localremovalists.css";
import Information from "./Information";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import "../Home/Home.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const googleMapsApiKey = "AIzaSyAtUa8t-LE_sdMtpNgvfBSbfE2Sil2cVvw";

const Localremovalists = () => {
  const pickupRef = useRef(null);
  const dropoffRef = useRef(null);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
      script.async = true;
      // script.onload = () => {
      //   if (window.google && window.google.maps) {
      //     new window.google.maps.places.Autocomplete(pickupRef.current, {
      //       types: ["geocode"],
      //     });
      //     new window.google.maps.places.Autocomplete(dropoffRef.current, {
      //       types: ["geocode"],
      //     });
      //   }
      // };

      script.onload = () => {
        if (window.google && window.google.maps) {
          const options = {
            types: ["geocode"],
            componentRestrictions: { country: "AU" },
          };

          new window.google.maps.places.Autocomplete(
            pickupRef.current,
            options
          );
          new window.google.maps.places.Autocomplete(
            dropoffRef.current,
            options
          );
        }
      };
      script.onerror = () => {
        console.error("Error loading Google Maps script");
      };
      document.head.appendChild(script);
    };

    loadGoogleMapsScript();
  }, []);

  const handleQuoteRequest = useCallback(async () => {
    const fromLocation = pickupRef.current?.value || "";
    const toLocation = dropoffRef.current?.value || "";

    // Validation check
    if (!fromLocation || !toLocation) {
      setErrorMessage("Please provide the locations");
      return;
    }

    const isValidAUAddress = (location) => {
      return location.includes("Australia") || location.includes("AU");
    };

    if (!isValidAUAddress(fromLocation) || !isValidAUAddress(toLocation)) {
      setErrorMessage("Please provide valid Australian locations");
      return;
    }

    try {
      const response = await fetch(
        "https://booking.skilledmovers.com.au/api/new-enquiry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fromlocation: fromLocation,
            tolocation: toLocation,
          }),
        }
      );

      const data = await response.json();
      console.log("Quote data:", data);

      if (data.success) {
        // Save data to local storage
        localStorage.setItem("enquiry_id", data.enquiry_id);
        localStorage.setItem("from_location", fromLocation);
        localStorage.setItem("to_location", toLocation);

        console.log(
          "Enquiry ID, from location, and to location saved to local storage"
        );
        navigate("/accessproperties");
        window.location.reload();
      } else {
        console.error("Failed to save enquiry:", data.message);
      }
    } catch (error) {
      console.error("Error fetching quote:", error);
    }
  }, [navigate]);
  
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []);

  return (
    <>
      {/* Add meta title and description */}
      <Helmet>
        <title>
          Removalists in Melbourne: Local Removalists - Skilled Movers
        </title>
        <meta
          name="description"
          content="As a leading Movers and removalist in Melbourne, we offer end-to-end packing, local removal, storage & packing services at the best price. Book your local move."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      {/* end of meta title */}

      <div className="banner__image mobile__banner__image">
        <div className="banner skilled__content">
          <div className="text-section mobile__text">
            <h1>
              Get Moving with <br />
              Skilled Movers Right Now
            </h1>
            <p className="m-1">
              One Of Australia’s Top Rated Moving & Cleaning Company.
            </p>
            {/* <p className="m-1 rating-text">
              4.9 <span className="gold-stars">★★★★★</span>
            </p>
            <p className="m-1">
              "Earned 248 Google Review from Satisfied Customers"
            </p> */}
          </div>
          <div className="form-section mobile__location w-50">
            <div className="moving-form">
              <h1 class="small-heading fs-4 font-weight-bold">
                Start your better move.
              </h1>

              <div className="form-group-container ">
                <div
                  className="form-group mb-0"
                  style={{ justifyContent: "center" }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                  <input
                    style={{
                      width: "calc(100% - 40px)", // Adjust width to fit the container
                      padding: "15px 15px 15px 40px", // Ensure padding accounts for the icon
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxSizing: "border-box", // Include padding and border in element's total width and height
                      fontSize: "1rem",
                    }}
                    type="text"
                    placeholder="Picking up from"
                    ref={pickupRef}
                    onChange={() => setErrorMessage("")}
                  />
                </div>
                <div
                  className="form-group mb-0"
                  style={{ justifyContent: "center" }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                  <input
                    style={{
                      width: "calc(100% - 40px)", // Adjust width to fit the container
                      padding: "15px 15px 15px 40px", // Ensure padding accounts for the icon
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxSizing: "border-box", // Include padding and border in element's total width and height
                      fontSize: "1rem",
                    }}
                    type="text"
                    placeholder="Dropping off at"
                    ref={dropoffRef}
                    onChange={() => setErrorMessage("")}
                  />
                </div>
                {/* Error message display */}
                {errorMessage && (
                  <p
                    style={{
                      color: "red",
                      textAlign: "center",
                      marginTop: "1rem",
                    }}
                  >
                    {errorMessage}
                  </p>
                )}
                <Link
                  to="/accessproperties"
                  className="quote-button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleQuoteRequest();
                  }}
                >
                  Get an Instant Quote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ========================================================= */}
      <div className="container my-5">
        <h2 className="text-center mb-3 mb-lg-5">Removalists in Melbourne</h2>
        <div className="row">
          <p style={{ textAlign: "justify" }}>
            Moving can be one of life’s most exciting yet stressful experiences.
            Whether you’re relocating across town or making a big move to the
            vibrant city of Melbourne, there’s a lot to consider. With the right
            help, though, this transition can become seamless and
            straightforward. That’s where professional removalists come into
            play. They not only manage heavy lifting but also take care of
            logistics that often overwhelm even the best planners.
          </p>
          <p style={{ textAlign: "justify" }}>
            If you're looking for local removalists traveling to or from
            Melbourne, it's essential to understand what sets Skilled Movers
            apart in this bustling metropolis. From packing your cherished
            belongings with care to ensuring they're safely transported, Skilled
            Movers have mastered every aspect of moving house in Melbourne.
            Let’s dive into some essential insights about hiring Skilled Movers
            as your removalists in Melbourne that will transform your next move
            into an effortless journey!
          </p>
        </div>
      </div>
      <Information />
    </>
  );
};

export default Localremovalists;
