import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import QuoteDetails from './QuoteDetail'; // Make sure the path is correct

const DetailCompleted = () => {
  const [data, setData] = useState([]);
  const [selectedEnquiryId, setSelectedEnquiryId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customer_id = localStorage.getItem('customer_id');
        const response = await fetch(
          `https://booking.skilledmovers.com.au/api/complete-detailed-estimate-bookings/${customer_id}`
        );
  
        const result = await response.json();
  
        if (response.ok && result.success) {
          const bookings = Object.values(result.enquirys).map((array) => array[0]);
          setData(bookings);
        } else {
          console.error('Failed to fetch data:', result.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  const handleColClick = (enquiry_id) => {
    setSelectedEnquiryId(enquiry_id);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Container id="scrollable-complete">
        <Row>
          {data.map((item, index) => (
            <Col 
              key={index} 
              className="mb-4" 
              xs={12} 
              onClick={() => handleColClick(item.enquiry_id)}
            >
              <div className="shadow p-3 bg-white rounded">
                <h5>Booking {index + 1}</h5>
                <div className="d-flex flex-column flex-sm-row justify-content-between mb-2">
                  <div className="font-weight-bold">Pickup Location:</div>
                  <div className="mt-1 mt-sm-0">{item.fromlocation}</div>
                </div>
                <div className="d-flex flex-column flex-sm-row justify-content-between mb-2">
                  <div className="font-weight-bold">Dropoff Location:</div>
                  <div className="mt-1 mt-sm-0">{item.tolocation}</div>
                </div>
                {/* <div className="d-flex flex-column flex-sm-row justify-content-between mb-2">
                  <div className="font-weight-bold">Total Cost:</div>
                  <div className="mt-1 mt-sm-0">${item.totalCost}</div>
                </div> */}
               
              </div>
            </Col>
          ))}
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Quote Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEnquiryId && <QuoteDetails enquiryId={selectedEnquiryId} />}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DetailCompleted;
