import React from "react";
// import logos from "../Assests/Skilled-Movers-Logo.png"
import logos from "../../Assests/Skilled-Movers-Logo.png";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const PaymentSuccess = () => {
  // Inline CSS styles
  const cardContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    background: "linear-gradient(180deg, #edeffd, #f8f9ff)",
    padding: "20px",
  };

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px", // Increased padding for more space inside the card
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px", // Increased max width for a larger card
    width: "100%",
    textAlign: "center",
    position: "relative", // For positioning the top icon
  };

  const messageStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#28a745",
    marginBottom: "20px",
  };

  const iconStyle = {
    fontSize: "100px",
    color: "##ff5c00",
    marginBottom: "20px", 
  };


  const topIconStyle = {
    position: "absolute",
    top: "-20px", // Position the icon above the card
    right: "50%",
    transform: "translateX(50%)",
    fontSize: "40px",
    color: "#007bff",
  };

  const handleGoHome = () => {
    localStorage.removeItem("DetailsEstimete");
    localStorage.removeItem("QuickEstimete");
    localStorage.removeItem("HandleEstimete");
    window.location.href = "/";
  };

  return (
    <div style={cardContainerStyle}>
      <img
        src={logos}
        alt="Logo"
        width="15%"
        style={{ marginBottom: "20px" }}
      />
      <div style={cardStyle}>
        <div style={topIconStyle}></div>
        <div style={iconStyle}>
          {/* <span role="img" aria-label="check" class="orange-check">
            ✔️
          </span> */}
                    <FontAwesomeIcon icon={faCheckCircle} className="thankyou-icon" />

        </div>
        <div style={messageStyle}>Payment Successful!</div>
        <button className="quote-button" onClick={handleGoHome}>
          Go to Home
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
